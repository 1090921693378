export default [{
	"igid": "S1",
	"parts": [{
		"goalGroup": "oxm337xeBBjn5lK7QGEq",
		"goals": ["elmGrOybd4rSauQb8I9O", "6Cr9IbXn1EEgCoIDS2WG", "fZGBzy4qOfBtEwvJaxVh", "PRuAbuCoIRTMl5xNastc", "0j7aMOmQiZsOxsUTmsxP", "uQaSPT1w02Z6SUaLiNyJ", "AtA44UePNrB477WoevQ6", "f4zxiqoyC7ku9rZnhwd1", "h7dIMbTZ5l5V85HT2PTi", "Hm7CNPCTtLEIHsohGBlx", "9NYgZJ5D6Ro3vsb7TSZX", "BO3zzlhAf5UipPMl7d5x", "gHSy7XIWPH0hGo8psC5l", "y0yAj7fmiqddHgTcLEHf", "5nD0brWdQjzW2gOnsV2s", "izRq2vmlzqnYccJgeThG", "GTODvuG9EjtXBe9qJS65", "0Y4eKEy4rvyuwViuzmb2", "pe2agZdOycnyQSJytpfq", "3Jmx0IyArNP3UlyTV085", "9SpOljv8p8E1VnACiRRz", "zcNz9UfXP4mrT3ufCYhU", "7POi274JRe7OpYlwCSYh", "FsZzFYu5DpQydZCsJtdl", "No2twavh69lyAWhBo7E1", "iGCPAYcd5n2FD1COaI4W", "tvBH4XYRPW2ifbcDXFYq", "1bsq9iCqvWtpWAvcOJVO", "fGkZmmS1I4PjjGtQP8F7", "6PVnm6lhDXeNoPzwvShM", "4e3Qm41GBUE1Fq79VBsf", "wRksgglcaFkYF4l6lMkG", "PoLb2xSXhHSj9a6RGWpI", "7h1YVXgYD5eogCRP7uRm", "joVA8dIQYIFmOGEMeqKa", "w8cTT3G7YJz7i8Bk9mB0", "VwSlZIC8aa3Kk0JJFcU8", "cUuKRl2dYQzM6mMfIDPJ", "7i2sSLWcf7Ofvr7NHmHF", "IHGorsoSRREpMsG0ca8F", "rvf9hxhmdmm5DCCt9Xi3", "HazgBWBeoqZrx77gcMvr"]
	}, {
		"goalGroup": "6HVUqhHse4RlZwyat0tU",
		"goals": ["EHNqY78g8EiDsFBQZsiM", "l5qQCdsktILMC86YMVMP", "BNiKcdVNFE8nCcNYDRk1", "vrb9vR1IBYEqk01clMoD", "718lmOXsSte3ar3I7MIW"]
	}, {
		"goalGroup": "3DBrDmy0hgn4HWliWpi3",
		"goals": ["n9D1n4DprY9rV5HvM94v", "CdnNWYWvXqVtIyovHjDC"]
	}, {
		"goalGroup": "zmGGmARgmLqtvXOF0nNa",
		"goals": ["65RZNwA94Fq9ohOrtEvS", "0SdTRGTVlVjQoTCsiKqC", "0YUBCQuxBOr8PROY9dIf", "Ibci9KWw8aWVxSN2RhEi", "Mk7hNPhWOF5EYb9gYi3i"]
	}, {
		"goalGroup": "TgWltqxxVBo73Av15ER6",
		"goals": ["oCSrzvT2HIpx6Hg6ppBn", "Vb1OdoFOTyi59kGpPUpq"]
	}, {
		"goalGroup": "O3Qw037SqCYhJs2NXmie",
		"goals": ["E5eW2VbR8L9b2uOarq3s", "PWsaOGHIsSX1NoHkn8wD", "5Tl2hVrt2BetONhVHFGb", "vNXllTmPiPWLLl08xxri", "xf3NBY8jN5MC0Xhb7MQU", "Y6IbZzWjBuJav0LzVIt0", "DTdcjsr3oe55F89EannD", "evFSNTz74SLBeEqcHWeL"]
	}, {
		"goalGroup": "1BW6pUbFpF77bRpdpaul",
		"goals": ["k9vRZB8uEeo47aJixv2b", "WnvVRiEIETS0b9JAK6fh", "s6Zi3dIj2FiMiX75FxeT", "4mulxh1oaS79Eh9ChFIP"]
	}, {
		"goalGroup": "skCEFaXepXHhbV5UEz8H",
		"goals": ["5xYvnV9U0OnVzmWhOGCt", "VouKOlCEWHpLPUweKiYQ", "nmz563qst2vYKAKuzEz1", "vhxICG24dM6i9iU1yrgG", "IWV2W14VaF0hf0oyLDOY", "YeFsrMTsTTUnEGvgFpTa", "u9sKA8vsyTaXSH8Rf7G6", "W5nqftweD6VJQBJtAJ3E", "yEDHfAiuZh9JN8jxHo0a", "KUc2b6iwzZuMY153a5FO", "Q9kvyv8TWhEN3UOCWA9r"]
	}, {
		"goalGroup": "sHai2tQJbjjXe6X8iY67",
		"goals": ["mHPVnA8DRwYd2JBE9rX2", "OMU93iIrAWq6IV48Ixdu", "hIfVGI8EBV7mySPITJHq"]
	}]
}, {
	"igid": "S2",
	"parts": [{
		"goalGroup": "oxm337xeBBjn5lK7QGEq",
		"goals": ["elmGrOybd4rSauQb8I9O", "6Cr9IbXn1EEgCoIDS2WG", "fZGBzy4qOfBtEwvJaxVh", "PRuAbuCoIRTMl5xNastc", "0j7aMOmQiZsOxsUTmsxP", "uQaSPT1w02Z6SUaLiNyJ", "AtA44UePNrB477WoevQ6", "f4zxiqoyC7ku9rZnhwd1", "h7dIMbTZ5l5V85HT2PTi", "Hm7CNPCTtLEIHsohGBlx", "9NYgZJ5D6Ro3vsb7TSZX", "BO3zzlhAf5UipPMl7d5x", "gHSy7XIWPH0hGo8psC5l", "y0yAj7fmiqddHgTcLEHf", "5nD0brWdQjzW2gOnsV2s", "izRq2vmlzqnYccJgeThG", "GTODvuG9EjtXBe9qJS65", "0Y4eKEy4rvyuwViuzmb2", "pe2agZdOycnyQSJytpfq", "3Jmx0IyArNP3UlyTV085", "QLRH13vislXJqMxS1CMl", "H2ZemTSHrq0sHR0elyJ8"]
	}, {
		"goalGroup": "6HVUqhHse4RlZwyat0tU",
		"goals": ["EHNqY78g8EiDsFBQZsiM", "l5qQCdsktILMC86YMVMP", "BNiKcdVNFE8nCcNYDRk1", "vrb9vR1IBYEqk01clMoD", "718lmOXsSte3ar3I7MIW"]
	}, {
		"goalGroup": "3DBrDmy0hgn4HWliWpi3",
		"goals": ["n9D1n4DprY9rV5HvM94v", "CdnNWYWvXqVtIyovHjDC"]
	}, {
		"goalGroup": "zmGGmARgmLqtvXOF0nNa",
		"goals": ["65RZNwA94Fq9ohOrtEvS", "0SdTRGTVlVjQoTCsiKqC", "0YUBCQuxBOr8PROY9dIf", "Ibci9KWw8aWVxSN2RhEi", "Mk7hNPhWOF5EYb9gYi3i"]
	}, {
		"goalGroup": "TgWltqxxVBo73Av15ER6",
		"goals": ["oCSrzvT2HIpx6Hg6ppBn", "Vb1OdoFOTyi59kGpPUpq", "b4DsjPkTJE3grtqP78j9", "OdPRlAZxp8q0efMgHGpB"]
	}, {
		"goalGroup": "O3Qw037SqCYhJs2NXmie",
		"goals": ["E5eW2VbR8L9b2uOarq3s", "PWsaOGHIsSX1NoHkn8wD", "5Tl2hVrt2BetONhVHFGb", "vNXllTmPiPWLLl08xxri", "xf3NBY8jN5MC0Xhb7MQU", "Y6IbZzWjBuJav0LzVIt0", "DTdcjsr3oe55F89EannD", "evFSNTz74SLBeEqcHWeL", "tRxno2o9xD7koS4DjhIp", "V0InBUyDRBsnOV5v1yCL"]
	}, {
		"goalGroup": "1BW6pUbFpF77bRpdpaul",
		"goals": ["k9vRZB8uEeo47aJixv2b", "WnvVRiEIETS0b9JAK6fh", "s6Zi3dIj2FiMiX75FxeT", "4mulxh1oaS79Eh9ChFIP"]
	}, {
		"goalGroup": "skCEFaXepXHhbV5UEz8H",
		"goals": ["5xYvnV9U0OnVzmWhOGCt", "VouKOlCEWHpLPUweKiYQ", "nmz563qst2vYKAKuzEz1", "vhxICG24dM6i9iU1yrgG", "IWV2W14VaF0hf0oyLDOY", "YeFsrMTsTTUnEGvgFpTa", "u9sKA8vsyTaXSH8Rf7G6", "W5nqftweD6VJQBJtAJ3E", "yEDHfAiuZh9JN8jxHo0a", "KUc2b6iwzZuMY153a5FO", "Q9kvyv8TWhEN3UOCWA9r"]
	}, {
		"goalGroup": "sHai2tQJbjjXe6X8iY67",
		"goals": ["mHPVnA8DRwYd2JBE9rX2", "OMU93iIrAWq6IV48Ixdu", "hIfVGI8EBV7mySPITJHq"]
	}]
}, {
	"igid": "S3",
	"parts": [{
		"goalGroup": "oxm337xeBBjn5lK7QGEq",
		"goals": ["elmGrOybd4rSauQb8I9O", "6Cr9IbXn1EEgCoIDS2WG", "fZGBzy4qOfBtEwvJaxVh", "PRuAbuCoIRTMl5xNastc", "0j7aMOmQiZsOxsUTmsxP", "uQaSPT1w02Z6SUaLiNyJ", "AtA44UePNrB477WoevQ6", "f4zxiqoyC7ku9rZnhwd1", "h7dIMbTZ5l5V85HT2PTi", "Hm7CNPCTtLEIHsohGBlx", "9NYgZJ5D6Ro3vsb7TSZX", "BO3zzlhAf5UipPMl7d5x", "gHSy7XIWPH0hGo8psC5l", "y0yAj7fmiqddHgTcLEHf", "5nD0brWdQjzW2gOnsV2s", "izRq2vmlzqnYccJgeThG", "GTODvuG9EjtXBe9qJS65", "0Y4eKEy4rvyuwViuzmb2", "pe2agZdOycnyQSJytpfq", "3Jmx0IyArNP3UlyTV085", "b9oyiVWrKRspZndSFRPM", "Uj7T0jBXqmhyzvl4vNzZ", "6Sw0FjlQHYM75jI3VR0l", "Vf9vDq28NTylpDEOvHKl", "CBL9U1OSABdaP23pZOGo", "HWPspAh3AA09zBlXiGpI", "H6JOGsk043THGzAVBJdj", "7R48tNNK6hbDrIjVkgii", "WULuXPqRRUsER9AcjDeL", "PPmv8YrikS0wgGujnHCC", "SyslJrG2IEvu0Z4zBpPg"]
	}, {
		"goalGroup": "6HVUqhHse4RlZwyat0tU",
		"goals": ["EHNqY78g8EiDsFBQZsiM", "l5qQCdsktILMC86YMVMP", "BNiKcdVNFE8nCcNYDRk1", "vrb9vR1IBYEqk01clMoD", "718lmOXsSte3ar3I7MIW", "cI76SbmOvxIyVaIhcuKP", "1ImiaYqXCuQI9Ifiw5TF", "tbJ4C0tplgVo5pzgSipB", "FAjdUFtom0OHjn9p7VtR", "wiUo7JnJgF3J1vsQ7v4f", "uN5XXYknxeADrEGdm1YD"]
	}, {
		"goalGroup": "3DBrDmy0hgn4HWliWpi3",
		"goals": ["n9D1n4DprY9rV5HvM94v", "CdnNWYWvXqVtIyovHjDC"]
	}, {
		"goalGroup": "zmGGmARgmLqtvXOF0nNa",
		"goals": ["65RZNwA94Fq9ohOrtEvS", "0SdTRGTVlVjQoTCsiKqC", "0YUBCQuxBOr8PROY9dIf", "Ibci9KWw8aWVxSN2RhEi", "Mk7hNPhWOF5EYb9gYi3i"]
	}, {
		"goalGroup": "TgWltqxxVBo73Av15ER6",
		"goals": ["oCSrzvT2HIpx6Hg6ppBn", "Vb1OdoFOTyi59kGpPUpq"]
	}, {
		"goalGroup": "O3Qw037SqCYhJs2NXmie",
		"goals": ["E5eW2VbR8L9b2uOarq3s", "PWsaOGHIsSX1NoHkn8wD", "5Tl2hVrt2BetONhVHFGb", "vNXllTmPiPWLLl08xxri", "xf3NBY8jN5MC0Xhb7MQU", "Y6IbZzWjBuJav0LzVIt0", "DTdcjsr3oe55F89EannD", "evFSNTz74SLBeEqcHWeL"]
	}, {
		"goalGroup": "1BW6pUbFpF77bRpdpaul",
		"goals": ["k9vRZB8uEeo47aJixv2b", "WnvVRiEIETS0b9JAK6fh", "s6Zi3dIj2FiMiX75FxeT", "4mulxh1oaS79Eh9ChFIP"]
	}, {
		"goalGroup": "skCEFaXepXHhbV5UEz8H",
		"goals": ["5xYvnV9U0OnVzmWhOGCt", "VouKOlCEWHpLPUweKiYQ", "nmz563qst2vYKAKuzEz1", "vhxICG24dM6i9iU1yrgG", "IWV2W14VaF0hf0oyLDOY", "YeFsrMTsTTUnEGvgFpTa", "u9sKA8vsyTaXSH8Rf7G6", "W5nqftweD6VJQBJtAJ3E", "yEDHfAiuZh9JN8jxHo0a", "KUc2b6iwzZuMY153a5FO", "Q9kvyv8TWhEN3UOCWA9r", "LGHWLSwfvBV3OhC27iAn"]
	}, {
		"goalGroup": "sHai2tQJbjjXe6X8iY67",
		"goals": ["mHPVnA8DRwYd2JBE9rX2", "OMU93iIrAWq6IV48Ixdu", "hIfVGI8EBV7mySPITJHq"]
	}]
}, {
	"igid": "S4",
	"parts": [{
		"goalGroup": "oxm337xeBBjn5lK7QGEq",
		"goals": ["elmGrOybd4rSauQb8I9O", "6Cr9IbXn1EEgCoIDS2WG", "fZGBzy4qOfBtEwvJaxVh", "PRuAbuCoIRTMl5xNastc", "0j7aMOmQiZsOxsUTmsxP", "uQaSPT1w02Z6SUaLiNyJ", "AtA44UePNrB477WoevQ6", "f4zxiqoyC7ku9rZnhwd1", "h7dIMbTZ5l5V85HT2PTi", "Hm7CNPCTtLEIHsohGBlx", "9NYgZJ5D6Ro3vsb7TSZX", "BO3zzlhAf5UipPMl7d5x", "gHSy7XIWPH0hGo8psC5l", "y0yAj7fmiqddHgTcLEHf", "5nD0brWdQjzW2gOnsV2s", "izRq2vmlzqnYccJgeThG", "GTODvuG9EjtXBe9qJS65", "0Y4eKEy4rvyuwViuzmb2", "pe2agZdOycnyQSJytpfq", "3Jmx0IyArNP3UlyTV085"]
	}, {
		"goalGroup": "6HVUqhHse4RlZwyat0tU",
		"goals": ["EHNqY78g8EiDsFBQZsiM", "l5qQCdsktILMC86YMVMP", "BNiKcdVNFE8nCcNYDRk1", "vrb9vR1IBYEqk01clMoD", "718lmOXsSte3ar3I7MIW"]
	}, {
		"goalGroup": "3DBrDmy0hgn4HWliWpi3",
		"goals": ["n9D1n4DprY9rV5HvM94v", "CdnNWYWvXqVtIyovHjDC"]
	}, {
		"goalGroup": "zmGGmARgmLqtvXOF0nNa",
		"goals": ["65RZNwA94Fq9ohOrtEvS", "0SdTRGTVlVjQoTCsiKqC", "0YUBCQuxBOr8PROY9dIf", "Ibci9KWw8aWVxSN2RhEi", "Mk7hNPhWOF5EYb9gYi3i"]
	}, {
		"goalGroup": "TgWltqxxVBo73Av15ER6",
		"goals": ["oCSrzvT2HIpx6Hg6ppBn", "Vb1OdoFOTyi59kGpPUpq"]
	}, {
		"goalGroup": "O3Qw037SqCYhJs2NXmie",
		"goals": ["E5eW2VbR8L9b2uOarq3s", "PWsaOGHIsSX1NoHkn8wD", "5Tl2hVrt2BetONhVHFGb", "vNXllTmPiPWLLl08xxri", "xf3NBY8jN5MC0Xhb7MQU", "Y6IbZzWjBuJav0LzVIt0", "DTdcjsr3oe55F89EannD", "evFSNTz74SLBeEqcHWeL"]
	}, {
		"goalGroup": "1BW6pUbFpF77bRpdpaul",
		"goals": ["k9vRZB8uEeo47aJixv2b", "WnvVRiEIETS0b9JAK6fh", "s6Zi3dIj2FiMiX75FxeT", "4mulxh1oaS79Eh9ChFIP"]
	}, {
		"goalGroup": "skCEFaXepXHhbV5UEz8H",
		"goals": ["5xYvnV9U0OnVzmWhOGCt", "VouKOlCEWHpLPUweKiYQ", "nmz563qst2vYKAKuzEz1", "vhxICG24dM6i9iU1yrgG", "IWV2W14VaF0hf0oyLDOY", "YeFsrMTsTTUnEGvgFpTa", "u9sKA8vsyTaXSH8Rf7G6", "W5nqftweD6VJQBJtAJ3E", "yEDHfAiuZh9JN8jxHo0a", "KUc2b6iwzZuMY153a5FO", "Q9kvyv8TWhEN3UOCWA9r"]
	}, {
		"goalGroup": "sHai2tQJbjjXe6X8iY67",
		"goals": ["mHPVnA8DRwYd2JBE9rX2", "OMU93iIrAWq6IV48Ixdu", "hIfVGI8EBV7mySPITJHq"]
	}]
}, {
	"igid": "S5",
	"parts": [{
		"goalGroup": "oxm337xeBBjn5lK7QGEq",
		"goals": ["elmGrOybd4rSauQb8I9O", "6Cr9IbXn1EEgCoIDS2WG", "fZGBzy4qOfBtEwvJaxVh", "PRuAbuCoIRTMl5xNastc", "0j7aMOmQiZsOxsUTmsxP", "uQaSPT1w02Z6SUaLiNyJ", "AtA44UePNrB477WoevQ6", "f4zxiqoyC7ku9rZnhwd1", "h7dIMbTZ5l5V85HT2PTi", "Hm7CNPCTtLEIHsohGBlx", "9NYgZJ5D6Ro3vsb7TSZX", "BO3zzlhAf5UipPMl7d5x", "gHSy7XIWPH0hGo8psC5l", "y0yAj7fmiqddHgTcLEHf", "5nD0brWdQjzW2gOnsV2s", "izRq2vmlzqnYccJgeThG", "GTODvuG9EjtXBe9qJS65", "0Y4eKEy4rvyuwViuzmb2", "pe2agZdOycnyQSJytpfq", "3Jmx0IyArNP3UlyTV085", "HaA3STPHBfnO15OrCFQt", "00KbWJuxbgHcKOJfA6BT", "SdXxdJbo5InfDgmqnvoZ"]
	}, {
		"goalGroup": "6HVUqhHse4RlZwyat0tU",
		"goals": ["EHNqY78g8EiDsFBQZsiM", "l5qQCdsktILMC86YMVMP", "BNiKcdVNFE8nCcNYDRk1", "vrb9vR1IBYEqk01clMoD", "718lmOXsSte3ar3I7MIW"]
	}, {
		"goalGroup": "3DBrDmy0hgn4HWliWpi3",
		"goals": ["n9D1n4DprY9rV5HvM94v", "CdnNWYWvXqVtIyovHjDC"]
	}, {
		"goalGroup": "zmGGmARgmLqtvXOF0nNa",
		"goals": ["65RZNwA94Fq9ohOrtEvS", "0SdTRGTVlVjQoTCsiKqC", "0YUBCQuxBOr8PROY9dIf", "Ibci9KWw8aWVxSN2RhEi", "Mk7hNPhWOF5EYb9gYi3i"]
	}, {
		"goalGroup": "TgWltqxxVBo73Av15ER6",
		"goals": ["oCSrzvT2HIpx6Hg6ppBn", "Vb1OdoFOTyi59kGpPUpq"]
	}, {
		"goalGroup": "O3Qw037SqCYhJs2NXmie",
		"goals": ["E5eW2VbR8L9b2uOarq3s", "PWsaOGHIsSX1NoHkn8wD", "5Tl2hVrt2BetONhVHFGb", "vNXllTmPiPWLLl08xxri", "xf3NBY8jN5MC0Xhb7MQU", "Y6IbZzWjBuJav0LzVIt0", "DTdcjsr3oe55F89EannD", "evFSNTz74SLBeEqcHWeL"]
	}, {
		"goalGroup": "1BW6pUbFpF77bRpdpaul",
		"goals": ["k9vRZB8uEeo47aJixv2b", "WnvVRiEIETS0b9JAK6fh", "s6Zi3dIj2FiMiX75FxeT", "4mulxh1oaS79Eh9ChFIP"]
	}, {
		"goalGroup": "skCEFaXepXHhbV5UEz8H",
		"goals": ["5xYvnV9U0OnVzmWhOGCt", "VouKOlCEWHpLPUweKiYQ", "nmz563qst2vYKAKuzEz1", "vhxICG24dM6i9iU1yrgG", "IWV2W14VaF0hf0oyLDOY", "YeFsrMTsTTUnEGvgFpTa", "u9sKA8vsyTaXSH8Rf7G6", "W5nqftweD6VJQBJtAJ3E", "yEDHfAiuZh9JN8jxHo0a", "KUc2b6iwzZuMY153a5FO", "Q9kvyv8TWhEN3UOCWA9r"]
	}, {
		"goalGroup": "sHai2tQJbjjXe6X8iY67",
		"goals": ["mHPVnA8DRwYd2JBE9rX2", "OMU93iIrAWq6IV48Ixdu", "hIfVGI8EBV7mySPITJHq"]
	}]
}, {
	"igid": "S6",
	"parts": [{
		"goalGroup": "oxm337xeBBjn5lK7QGEq",
		"goals": ["elmGrOybd4rSauQb8I9O", "6Cr9IbXn1EEgCoIDS2WG", "fZGBzy4qOfBtEwvJaxVh", "PRuAbuCoIRTMl5xNastc", "0j7aMOmQiZsOxsUTmsxP", "uQaSPT1w02Z6SUaLiNyJ", "AtA44UePNrB477WoevQ6", "f4zxiqoyC7ku9rZnhwd1", "h7dIMbTZ5l5V85HT2PTi", "Hm7CNPCTtLEIHsohGBlx", "9NYgZJ5D6Ro3vsb7TSZX", "BO3zzlhAf5UipPMl7d5x", "gHSy7XIWPH0hGo8psC5l", "y0yAj7fmiqddHgTcLEHf", "5nD0brWdQjzW2gOnsV2s", "izRq2vmlzqnYccJgeThG", "GTODvuG9EjtXBe9qJS65", "0Y4eKEy4rvyuwViuzmb2", "pe2agZdOycnyQSJytpfq", "3Jmx0IyArNP3UlyTV085"]
	}, {
		"goalGroup": "6HVUqhHse4RlZwyat0tU",
		"goals": ["EHNqY78g8EiDsFBQZsiM", "l5qQCdsktILMC86YMVMP", "BNiKcdVNFE8nCcNYDRk1", "vrb9vR1IBYEqk01clMoD", "718lmOXsSte3ar3I7MIW"]
	}, {
		"goalGroup": "3DBrDmy0hgn4HWliWpi3",
		"goals": ["n9D1n4DprY9rV5HvM94v", "CdnNWYWvXqVtIyovHjDC"]
	}, {
		"goalGroup": "zmGGmARgmLqtvXOF0nNa",
		"goals": ["65RZNwA94Fq9ohOrtEvS", "0SdTRGTVlVjQoTCsiKqC", "0YUBCQuxBOr8PROY9dIf", "Ibci9KWw8aWVxSN2RhEi", "Mk7hNPhWOF5EYb9gYi3i"]
	}, {
		"goalGroup": "TgWltqxxVBo73Av15ER6",
		"goals": ["oCSrzvT2HIpx6Hg6ppBn", "Vb1OdoFOTyi59kGpPUpq"]
	}, {
		"goalGroup": "O3Qw037SqCYhJs2NXmie",
		"goals": ["E5eW2VbR8L9b2uOarq3s", "PWsaOGHIsSX1NoHkn8wD", "5Tl2hVrt2BetONhVHFGb", "vNXllTmPiPWLLl08xxri", "xf3NBY8jN5MC0Xhb7MQU", "Y6IbZzWjBuJav0LzVIt0", "DTdcjsr3oe55F89EannD", "evFSNTz74SLBeEqcHWeL"]
	}, {
		"goalGroup": "1BW6pUbFpF77bRpdpaul",
		"goals": ["k9vRZB8uEeo47aJixv2b", "WnvVRiEIETS0b9JAK6fh", "s6Zi3dIj2FiMiX75FxeT", "4mulxh1oaS79Eh9ChFIP"]
	}, {
		"goalGroup": "skCEFaXepXHhbV5UEz8H",
		"goals": ["5xYvnV9U0OnVzmWhOGCt", "VouKOlCEWHpLPUweKiYQ", "nmz563qst2vYKAKuzEz1", "vhxICG24dM6i9iU1yrgG", "IWV2W14VaF0hf0oyLDOY", "YeFsrMTsTTUnEGvgFpTa", "u9sKA8vsyTaXSH8Rf7G6", "W5nqftweD6VJQBJtAJ3E", "yEDHfAiuZh9JN8jxHo0a", "KUc2b6iwzZuMY153a5FO", "Q9kvyv8TWhEN3UOCWA9r"]
	}, {
		"goalGroup": "sHai2tQJbjjXe6X8iY67",
		"goals": ["mHPVnA8DRwYd2JBE9rX2", "OMU93iIrAWq6IV48Ixdu", "hIfVGI8EBV7mySPITJHq"]
	}]
}, {
	"igid": "S7",
	"parts": [{
		"goalGroup": "oxm337xeBBjn5lK7QGEq",
		"goals": ["elmGrOybd4rSauQb8I9O", "6Cr9IbXn1EEgCoIDS2WG", "fZGBzy4qOfBtEwvJaxVh", "PRuAbuCoIRTMl5xNastc", "0j7aMOmQiZsOxsUTmsxP", "uQaSPT1w02Z6SUaLiNyJ", "AtA44UePNrB477WoevQ6", "f4zxiqoyC7ku9rZnhwd1", "h7dIMbTZ5l5V85HT2PTi", "Hm7CNPCTtLEIHsohGBlx", "9NYgZJ5D6Ro3vsb7TSZX", "BO3zzlhAf5UipPMl7d5x", "gHSy7XIWPH0hGo8psC5l", "y0yAj7fmiqddHgTcLEHf", "5nD0brWdQjzW2gOnsV2s", "izRq2vmlzqnYccJgeThG", "GTODvuG9EjtXBe9qJS65", "0Y4eKEy4rvyuwViuzmb2", "pe2agZdOycnyQSJytpfq", "3Jmx0IyArNP3UlyTV085"]
	}, {
		"goalGroup": "6HVUqhHse4RlZwyat0tU",
		"goals": ["EHNqY78g8EiDsFBQZsiM", "l5qQCdsktILMC86YMVMP", "BNiKcdVNFE8nCcNYDRk1", "vrb9vR1IBYEqk01clMoD", "718lmOXsSte3ar3I7MIW"]
	}, {
		"goalGroup": "3DBrDmy0hgn4HWliWpi3",
		"goals": ["n9D1n4DprY9rV5HvM94v", "CdnNWYWvXqVtIyovHjDC"]
	}, {
		"goalGroup": "zmGGmARgmLqtvXOF0nNa",
		"goals": ["65RZNwA94Fq9ohOrtEvS", "0SdTRGTVlVjQoTCsiKqC", "0YUBCQuxBOr8PROY9dIf", "Ibci9KWw8aWVxSN2RhEi", "Mk7hNPhWOF5EYb9gYi3i"]
	}, {
		"goalGroup": "TgWltqxxVBo73Av15ER6",
		"goals": ["oCSrzvT2HIpx6Hg6ppBn", "Vb1OdoFOTyi59kGpPUpq"]
	}, {
		"goalGroup": "O3Qw037SqCYhJs2NXmie",
		"goals": ["E5eW2VbR8L9b2uOarq3s", "PWsaOGHIsSX1NoHkn8wD", "5Tl2hVrt2BetONhVHFGb", "vNXllTmPiPWLLl08xxri", "xf3NBY8jN5MC0Xhb7MQU", "Y6IbZzWjBuJav0LzVIt0", "DTdcjsr3oe55F89EannD", "evFSNTz74SLBeEqcHWeL"]
	}, {
		"goalGroup": "1BW6pUbFpF77bRpdpaul",
		"goals": ["k9vRZB8uEeo47aJixv2b", "WnvVRiEIETS0b9JAK6fh", "s6Zi3dIj2FiMiX75FxeT", "4mulxh1oaS79Eh9ChFIP"]
	}, {
		"goalGroup": "skCEFaXepXHhbV5UEz8H",
		"goals": ["5xYvnV9U0OnVzmWhOGCt", "VouKOlCEWHpLPUweKiYQ", "nmz563qst2vYKAKuzEz1", "vhxICG24dM6i9iU1yrgG", "IWV2W14VaF0hf0oyLDOY", "YeFsrMTsTTUnEGvgFpTa", "u9sKA8vsyTaXSH8Rf7G6", "W5nqftweD6VJQBJtAJ3E", "yEDHfAiuZh9JN8jxHo0a", "KUc2b6iwzZuMY153a5FO", "Q9kvyv8TWhEN3UOCWA9r"]
	}, {
		"goalGroup": "sHai2tQJbjjXe6X8iY67",
		"goals": ["mHPVnA8DRwYd2JBE9rX2", "OMU93iIrAWq6IV48Ixdu", "hIfVGI8EBV7mySPITJHq"]
	}]
}, {
	"igid": "S8",
	"parts": [{
		"goalGroup": "oxm337xeBBjn5lK7QGEq",
		"goals": ["elmGrOybd4rSauQb8I9O", "6Cr9IbXn1EEgCoIDS2WG", "fZGBzy4qOfBtEwvJaxVh", "PRuAbuCoIRTMl5xNastc", "0j7aMOmQiZsOxsUTmsxP", "uQaSPT1w02Z6SUaLiNyJ", "AtA44UePNrB477WoevQ6", "f4zxiqoyC7ku9rZnhwd1", "h7dIMbTZ5l5V85HT2PTi", "Hm7CNPCTtLEIHsohGBlx", "9NYgZJ5D6Ro3vsb7TSZX", "BO3zzlhAf5UipPMl7d5x", "gHSy7XIWPH0hGo8psC5l", "y0yAj7fmiqddHgTcLEHf", "5nD0brWdQjzW2gOnsV2s", "izRq2vmlzqnYccJgeThG", "GTODvuG9EjtXBe9qJS65", "0Y4eKEy4rvyuwViuzmb2", "pe2agZdOycnyQSJytpfq", "3Jmx0IyArNP3UlyTV085"]
	}, {
		"goalGroup": "6HVUqhHse4RlZwyat0tU",
		"goals": ["EHNqY78g8EiDsFBQZsiM", "l5qQCdsktILMC86YMVMP", "BNiKcdVNFE8nCcNYDRk1", "vrb9vR1IBYEqk01clMoD", "718lmOXsSte3ar3I7MIW"]
	}, {
		"goalGroup": "3DBrDmy0hgn4HWliWpi3",
		"goals": ["n9D1n4DprY9rV5HvM94v", "CdnNWYWvXqVtIyovHjDC"]
	}, {
		"goalGroup": "zmGGmARgmLqtvXOF0nNa",
		"goals": ["65RZNwA94Fq9ohOrtEvS", "0SdTRGTVlVjQoTCsiKqC", "0YUBCQuxBOr8PROY9dIf", "Ibci9KWw8aWVxSN2RhEi", "Mk7hNPhWOF5EYb9gYi3i"]
	}, {
		"goalGroup": "TgWltqxxVBo73Av15ER6",
		"goals": ["oCSrzvT2HIpx6Hg6ppBn", "Vb1OdoFOTyi59kGpPUpq"]
	}, {
		"goalGroup": "O3Qw037SqCYhJs2NXmie",
		"goals": ["E5eW2VbR8L9b2uOarq3s", "PWsaOGHIsSX1NoHkn8wD", "5Tl2hVrt2BetONhVHFGb", "vNXllTmPiPWLLl08xxri", "xf3NBY8jN5MC0Xhb7MQU", "Y6IbZzWjBuJav0LzVIt0", "DTdcjsr3oe55F89EannD", "evFSNTz74SLBeEqcHWeL"]
	}, {
		"goalGroup": "1BW6pUbFpF77bRpdpaul",
		"goals": ["k9vRZB8uEeo47aJixv2b", "WnvVRiEIETS0b9JAK6fh", "s6Zi3dIj2FiMiX75FxeT", "4mulxh1oaS79Eh9ChFIP"]
	}, {
		"goalGroup": "skCEFaXepXHhbV5UEz8H",
		"goals": ["5xYvnV9U0OnVzmWhOGCt", "VouKOlCEWHpLPUweKiYQ", "nmz563qst2vYKAKuzEz1", "vhxICG24dM6i9iU1yrgG", "IWV2W14VaF0hf0oyLDOY", "YeFsrMTsTTUnEGvgFpTa", "u9sKA8vsyTaXSH8Rf7G6", "W5nqftweD6VJQBJtAJ3E", "yEDHfAiuZh9JN8jxHo0a", "KUc2b6iwzZuMY153a5FO", "Q9kvyv8TWhEN3UOCWA9r"]
	}, {
		"goalGroup": "sHai2tQJbjjXe6X8iY67",
		"goals": ["mHPVnA8DRwYd2JBE9rX2", "OMU93iIrAWq6IV48Ixdu", "hIfVGI8EBV7mySPITJHq"]
	}]
}, {
	"igid": "S9",
	"parts": [{
		"goalGroup": "oxm337xeBBjn5lK7QGEq",
		"goals": ["elmGrOybd4rSauQb8I9O", "6Cr9IbXn1EEgCoIDS2WG", "fZGBzy4qOfBtEwvJaxVh", "PRuAbuCoIRTMl5xNastc", "0j7aMOmQiZsOxsUTmsxP", "uQaSPT1w02Z6SUaLiNyJ", "AtA44UePNrB477WoevQ6", "f4zxiqoyC7ku9rZnhwd1", "h7dIMbTZ5l5V85HT2PTi", "Hm7CNPCTtLEIHsohGBlx", "9NYgZJ5D6Ro3vsb7TSZX", "BO3zzlhAf5UipPMl7d5x", "gHSy7XIWPH0hGo8psC5l", "y0yAj7fmiqddHgTcLEHf", "5nD0brWdQjzW2gOnsV2s", "izRq2vmlzqnYccJgeThG", "GTODvuG9EjtXBe9qJS65", "0Y4eKEy4rvyuwViuzmb2", "pe2agZdOycnyQSJytpfq", "3Jmx0IyArNP3UlyTV085", "GRUaJaske1jSZSY62cuG", "ABbOUkShreF7IZwU6TOx", "SgnOdpHaS0jqjQUYI0Ra", "NdNqXITp0sjWPTpJcynl"]
	}, {
		"goalGroup": "6HVUqhHse4RlZwyat0tU",
		"goals": ["EHNqY78g8EiDsFBQZsiM", "l5qQCdsktILMC86YMVMP", "BNiKcdVNFE8nCcNYDRk1", "vrb9vR1IBYEqk01clMoD", "718lmOXsSte3ar3I7MIW"]
	}, {
		"goalGroup": "3DBrDmy0hgn4HWliWpi3",
		"goals": ["n9D1n4DprY9rV5HvM94v", "CdnNWYWvXqVtIyovHjDC"]
	}, {
		"goalGroup": "zmGGmARgmLqtvXOF0nNa",
		"goals": ["65RZNwA94Fq9ohOrtEvS", "0SdTRGTVlVjQoTCsiKqC", "0YUBCQuxBOr8PROY9dIf", "Ibci9KWw8aWVxSN2RhEi", "Mk7hNPhWOF5EYb9gYi3i"]
	}, {
		"goalGroup": "TgWltqxxVBo73Av15ER6",
		"goals": ["oCSrzvT2HIpx6Hg6ppBn", "Vb1OdoFOTyi59kGpPUpq"]
	}, {
		"goalGroup": "O3Qw037SqCYhJs2NXmie",
		"goals": ["E5eW2VbR8L9b2uOarq3s", "PWsaOGHIsSX1NoHkn8wD", "5Tl2hVrt2BetONhVHFGb", "vNXllTmPiPWLLl08xxri", "xf3NBY8jN5MC0Xhb7MQU", "Y6IbZzWjBuJav0LzVIt0", "DTdcjsr3oe55F89EannD", "evFSNTz74SLBeEqcHWeL"]
	}, {
		"goalGroup": "1BW6pUbFpF77bRpdpaul",
		"goals": ["k9vRZB8uEeo47aJixv2b", "WnvVRiEIETS0b9JAK6fh", "s6Zi3dIj2FiMiX75FxeT", "4mulxh1oaS79Eh9ChFIP", "1NK0EPVWB71NbXgjPplg"]
	}, {
		"goalGroup": "skCEFaXepXHhbV5UEz8H",
		"goals": ["5xYvnV9U0OnVzmWhOGCt", "VouKOlCEWHpLPUweKiYQ", "nmz563qst2vYKAKuzEz1", "vhxICG24dM6i9iU1yrgG", "IWV2W14VaF0hf0oyLDOY", "YeFsrMTsTTUnEGvgFpTa", "u9sKA8vsyTaXSH8Rf7G6", "W5nqftweD6VJQBJtAJ3E", "yEDHfAiuZh9JN8jxHo0a", "KUc2b6iwzZuMY153a5FO", "Q9kvyv8TWhEN3UOCWA9r"]
	}, {
		"goalGroup": "sHai2tQJbjjXe6X8iY67",
		"goals": ["mHPVnA8DRwYd2JBE9rX2", "OMU93iIrAWq6IV48Ixdu", "hIfVGI8EBV7mySPITJHq"]
	}]
}, {
	"igid": "S10",
	"parts": [{
		"goalGroup": "oxm337xeBBjn5lK7QGEq",
		"goals": ["elmGrOybd4rSauQb8I9O", "6Cr9IbXn1EEgCoIDS2WG", "fZGBzy4qOfBtEwvJaxVh", "PRuAbuCoIRTMl5xNastc", "0j7aMOmQiZsOxsUTmsxP", "uQaSPT1w02Z6SUaLiNyJ", "AtA44UePNrB477WoevQ6", "f4zxiqoyC7ku9rZnhwd1", "h7dIMbTZ5l5V85HT2PTi", "Hm7CNPCTtLEIHsohGBlx", "9NYgZJ5D6Ro3vsb7TSZX", "BO3zzlhAf5UipPMl7d5x", "gHSy7XIWPH0hGo8psC5l", "y0yAj7fmiqddHgTcLEHf", "5nD0brWdQjzW2gOnsV2s", "izRq2vmlzqnYccJgeThG", "GTODvuG9EjtXBe9qJS65", "0Y4eKEy4rvyuwViuzmb2", "pe2agZdOycnyQSJytpfq", "3Jmx0IyArNP3UlyTV085"]
	}, {
		"goalGroup": "6HVUqhHse4RlZwyat0tU",
		"goals": ["dJsGoGIXpvCw2IiVTQI7", "EHNqY78g8EiDsFBQZsiM", "l5qQCdsktILMC86YMVMP", "BNiKcdVNFE8nCcNYDRk1", "vrb9vR1IBYEqk01clMoD", "718lmOXsSte3ar3I7MIW"]
	}, {
		"goalGroup": "3DBrDmy0hgn4HWliWpi3",
		"goals": ["7hgqWBYmzNThuQBQkZPx", "n9D1n4DprY9rV5HvM94v", "CdnNWYWvXqVtIyovHjDC"]
	}, {
		"goalGroup": "zmGGmARgmLqtvXOF0nNa",
		"goals": ["65RZNwA94Fq9ohOrtEvS", "0SdTRGTVlVjQoTCsiKqC", "0YUBCQuxBOr8PROY9dIf", "Ibci9KWw8aWVxSN2RhEi", "Mk7hNPhWOF5EYb9gYi3i"]
	}, {
		"goalGroup": "TgWltqxxVBo73Av15ER6",
		"goals": ["oCSrzvT2HIpx6Hg6ppBn", "Vb1OdoFOTyi59kGpPUpq"]
	}, {
		"goalGroup": "O3Qw037SqCYhJs2NXmie",
		"goals": ["E5eW2VbR8L9b2uOarq3s", "PWsaOGHIsSX1NoHkn8wD", "5Tl2hVrt2BetONhVHFGb", "vNXllTmPiPWLLl08xxri", "xf3NBY8jN5MC0Xhb7MQU", "Y6IbZzWjBuJav0LzVIt0", "DTdcjsr3oe55F89EannD", "evFSNTz74SLBeEqcHWeL"]
	}, {
		"goalGroup": "1BW6pUbFpF77bRpdpaul",
		"goals": ["k9vRZB8uEeo47aJixv2b", "WnvVRiEIETS0b9JAK6fh", "s6Zi3dIj2FiMiX75FxeT", "4mulxh1oaS79Eh9ChFIP"]
	}, {
		"goalGroup": "skCEFaXepXHhbV5UEz8H",
		"goals": ["5xYvnV9U0OnVzmWhOGCt", "VouKOlCEWHpLPUweKiYQ", "nmz563qst2vYKAKuzEz1", "vhxICG24dM6i9iU1yrgG", "IWV2W14VaF0hf0oyLDOY", "YeFsrMTsTTUnEGvgFpTa", "u9sKA8vsyTaXSH8Rf7G6", "W5nqftweD6VJQBJtAJ3E", "yEDHfAiuZh9JN8jxHo0a", "KUc2b6iwzZuMY153a5FO", "Q9kvyv8TWhEN3UOCWA9r"]
	}, {
		"goalGroup": "sHai2tQJbjjXe6X8iY67",
		"goals": ["mHPVnA8DRwYd2JBE9rX2", "OMU93iIrAWq6IV48Ixdu", "hIfVGI8EBV7mySPITJHq"]
	}]
}, {
	"igid": "S11",
	"parts": [{
		"goalGroup": "oxm337xeBBjn5lK7QGEq",
		"goals": ["elmGrOybd4rSauQb8I9O", "6Cr9IbXn1EEgCoIDS2WG", "fZGBzy4qOfBtEwvJaxVh", "PRuAbuCoIRTMl5xNastc", "0j7aMOmQiZsOxsUTmsxP", "uQaSPT1w02Z6SUaLiNyJ", "AtA44UePNrB477WoevQ6", "f4zxiqoyC7ku9rZnhwd1", "h7dIMbTZ5l5V85HT2PTi", "Hm7CNPCTtLEIHsohGBlx", "9NYgZJ5D6Ro3vsb7TSZX", "BO3zzlhAf5UipPMl7d5x", "gHSy7XIWPH0hGo8psC5l", "y0yAj7fmiqddHgTcLEHf", "5nD0brWdQjzW2gOnsV2s", "izRq2vmlzqnYccJgeThG", "GTODvuG9EjtXBe9qJS65", "0Y4eKEy4rvyuwViuzmb2", "pe2agZdOycnyQSJytpfq", "3Jmx0IyArNP3UlyTV085"]
	}, {
		"goalGroup": "6HVUqhHse4RlZwyat0tU",
		"goals": ["EHNqY78g8EiDsFBQZsiM", "l5qQCdsktILMC86YMVMP", "BNiKcdVNFE8nCcNYDRk1", "vrb9vR1IBYEqk01clMoD", "718lmOXsSte3ar3I7MIW"]
	}, {
		"goalGroup": "3DBrDmy0hgn4HWliWpi3",
		"goals": ["n9D1n4DprY9rV5HvM94v", "CdnNWYWvXqVtIyovHjDC"]
	}, {
		"goalGroup": "zmGGmARgmLqtvXOF0nNa",
		"goals": ["65RZNwA94Fq9ohOrtEvS", "0SdTRGTVlVjQoTCsiKqC", "0YUBCQuxBOr8PROY9dIf", "Ibci9KWw8aWVxSN2RhEi", "Mk7hNPhWOF5EYb9gYi3i"]
	}, {
		"goalGroup": "TgWltqxxVBo73Av15ER6",
		"goals": ["oCSrzvT2HIpx6Hg6ppBn", "Vb1OdoFOTyi59kGpPUpq"]
	}, {
		"goalGroup": "O3Qw037SqCYhJs2NXmie",
		"goals": ["E5eW2VbR8L9b2uOarq3s", "PWsaOGHIsSX1NoHkn8wD", "5Tl2hVrt2BetONhVHFGb", "vNXllTmPiPWLLl08xxri", "xf3NBY8jN5MC0Xhb7MQU", "Y6IbZzWjBuJav0LzVIt0", "DTdcjsr3oe55F89EannD", "evFSNTz74SLBeEqcHWeL"]
	}, {
		"goalGroup": "1BW6pUbFpF77bRpdpaul",
		"goals": ["k9vRZB8uEeo47aJixv2b", "WnvVRiEIETS0b9JAK6fh", "s6Zi3dIj2FiMiX75FxeT", "4mulxh1oaS79Eh9ChFIP"]
	}, {
		"goalGroup": "skCEFaXepXHhbV5UEz8H",
		"goals": ["5xYvnV9U0OnVzmWhOGCt", "VouKOlCEWHpLPUweKiYQ", "nmz563qst2vYKAKuzEz1", "vhxICG24dM6i9iU1yrgG", "IWV2W14VaF0hf0oyLDOY", "YeFsrMTsTTUnEGvgFpTa", "u9sKA8vsyTaXSH8Rf7G6", "W5nqftweD6VJQBJtAJ3E", "yEDHfAiuZh9JN8jxHo0a", "KUc2b6iwzZuMY153a5FO", "Q9kvyv8TWhEN3UOCWA9r"]
	}, {
		"goalGroup": "sHai2tQJbjjXe6X8iY67",
		"goals": ["mHPVnA8DRwYd2JBE9rX2", "OMU93iIrAWq6IV48Ixdu", "hIfVGI8EBV7mySPITJHq"]
	}]
}, {
	"igid": "S12",
	"parts": [{
		"goalGroup": "oxm337xeBBjn5lK7QGEq",
		"goals": ["elmGrOybd4rSauQb8I9O", "6Cr9IbXn1EEgCoIDS2WG", "fZGBzy4qOfBtEwvJaxVh", "PRuAbuCoIRTMl5xNastc", "0j7aMOmQiZsOxsUTmsxP", "uQaSPT1w02Z6SUaLiNyJ", "AtA44UePNrB477WoevQ6", "f4zxiqoyC7ku9rZnhwd1", "h7dIMbTZ5l5V85HT2PTi", "Hm7CNPCTtLEIHsohGBlx", "9NYgZJ5D6Ro3vsb7TSZX", "BO3zzlhAf5UipPMl7d5x", "gHSy7XIWPH0hGo8psC5l", "y0yAj7fmiqddHgTcLEHf", "5nD0brWdQjzW2gOnsV2s", "izRq2vmlzqnYccJgeThG", "GTODvuG9EjtXBe9qJS65", "0Y4eKEy4rvyuwViuzmb2", "pe2agZdOycnyQSJytpfq", "3Jmx0IyArNP3UlyTV085", "hN45J8eMg04nkPASjs7a", "kKIySfl4qklniwOCKyVc", "64gp0xH5eRNkL0Td72Dl", "ShAXPXxgKe9y30rF6H2m", "KTmdMsAkPuBsvZX0MmKo", "zm6ltJjySUzWqHtB1ANR", "dVxycWXIHLdDvbUqhHne", "5o4Zqw738Cpk1sw7EOZh", "Gmbfc3GCeUfGOaGnYXo3"]
	}, {
		"goalGroup": "6HVUqhHse4RlZwyat0tU",
		"goals": ["EHNqY78g8EiDsFBQZsiM", "l5qQCdsktILMC86YMVMP", "BNiKcdVNFE8nCcNYDRk1", "vrb9vR1IBYEqk01clMoD", "718lmOXsSte3ar3I7MIW"]
	}, {
		"goalGroup": "3DBrDmy0hgn4HWliWpi3",
		"goals": ["n9D1n4DprY9rV5HvM94v", "CdnNWYWvXqVtIyovHjDC"]
	}, {
		"goalGroup": "zmGGmARgmLqtvXOF0nNa",
		"goals": ["65RZNwA94Fq9ohOrtEvS", "0SdTRGTVlVjQoTCsiKqC", "0YUBCQuxBOr8PROY9dIf", "Ibci9KWw8aWVxSN2RhEi", "Mk7hNPhWOF5EYb9gYi3i"]
	}, {
		"goalGroup": "TgWltqxxVBo73Av15ER6",
		"goals": ["oCSrzvT2HIpx6Hg6ppBn", "Vb1OdoFOTyi59kGpPUpq"]
	}, {
		"goalGroup": "O3Qw037SqCYhJs2NXmie",
		"goals": ["E5eW2VbR8L9b2uOarq3s", "PWsaOGHIsSX1NoHkn8wD", "5Tl2hVrt2BetONhVHFGb", "vNXllTmPiPWLLl08xxri", "xf3NBY8jN5MC0Xhb7MQU", "Y6IbZzWjBuJav0LzVIt0", "DTdcjsr3oe55F89EannD", "evFSNTz74SLBeEqcHWeL", "PzYXmJfSc1KKLYfxoK1N"]
	}, {
		"goalGroup": "1BW6pUbFpF77bRpdpaul",
		"goals": ["k9vRZB8uEeo47aJixv2b", "WnvVRiEIETS0b9JAK6fh", "s6Zi3dIj2FiMiX75FxeT", "4mulxh1oaS79Eh9ChFIP", "CTgi9SPslG74AMLenDCd", "1NqAmkq63yOwT7VpfLWM"]
	}, {
		"goalGroup": "skCEFaXepXHhbV5UEz8H",
		"goals": ["5xYvnV9U0OnVzmWhOGCt", "VouKOlCEWHpLPUweKiYQ", "nmz563qst2vYKAKuzEz1", "vhxICG24dM6i9iU1yrgG", "IWV2W14VaF0hf0oyLDOY", "YeFsrMTsTTUnEGvgFpTa", "u9sKA8vsyTaXSH8Rf7G6", "W5nqftweD6VJQBJtAJ3E", "yEDHfAiuZh9JN8jxHo0a", "KUc2b6iwzZuMY153a5FO", "Q9kvyv8TWhEN3UOCWA9r", "gpu8OVzmHxEvWpHAhSGu", "PkF9eUAS1obpUlV30QZ6", "A9fYV42sGIdVGKllxrAC"]
	}, {
		"goalGroup": "sHai2tQJbjjXe6X8iY67",
		"goals": ["mHPVnA8DRwYd2JBE9rX2", "OMU93iIrAWq6IV48Ixdu", "hIfVGI8EBV7mySPITJHq"]
	}]
}, {
	"igid": "S13",
	"parts": [{
		"goalGroup": "oxm337xeBBjn5lK7QGEq",
		"goals": ["elmGrOybd4rSauQb8I9O", "6Cr9IbXn1EEgCoIDS2WG", "fZGBzy4qOfBtEwvJaxVh", "PRuAbuCoIRTMl5xNastc", "0j7aMOmQiZsOxsUTmsxP", "uQaSPT1w02Z6SUaLiNyJ", "AtA44UePNrB477WoevQ6", "f4zxiqoyC7ku9rZnhwd1", "h7dIMbTZ5l5V85HT2PTi", "Hm7CNPCTtLEIHsohGBlx", "9NYgZJ5D6Ro3vsb7TSZX", "BO3zzlhAf5UipPMl7d5x", "gHSy7XIWPH0hGo8psC5l", "y0yAj7fmiqddHgTcLEHf", "5nD0brWdQjzW2gOnsV2s", "izRq2vmlzqnYccJgeThG", "GTODvuG9EjtXBe9qJS65", "0Y4eKEy4rvyuwViuzmb2", "pe2agZdOycnyQSJytpfq", "3Jmx0IyArNP3UlyTV085"]
	}, {
		"goalGroup": "6HVUqhHse4RlZwyat0tU",
		"goals": ["EHNqY78g8EiDsFBQZsiM", "l5qQCdsktILMC86YMVMP", "BNiKcdVNFE8nCcNYDRk1", "vrb9vR1IBYEqk01clMoD", "718lmOXsSte3ar3I7MIW"]
	}, {
		"goalGroup": "3DBrDmy0hgn4HWliWpi3",
		"goals": ["n9D1n4DprY9rV5HvM94v", "CdnNWYWvXqVtIyovHjDC"]
	}, {
		"goalGroup": "zmGGmARgmLqtvXOF0nNa",
		"goals": ["65RZNwA94Fq9ohOrtEvS", "0SdTRGTVlVjQoTCsiKqC", "0YUBCQuxBOr8PROY9dIf", "Ibci9KWw8aWVxSN2RhEi", "Mk7hNPhWOF5EYb9gYi3i"]
	}, {
		"goalGroup": "TgWltqxxVBo73Av15ER6",
		"goals": ["oCSrzvT2HIpx6Hg6ppBn", "Vb1OdoFOTyi59kGpPUpq"]
	}, {
		"goalGroup": "O3Qw037SqCYhJs2NXmie",
		"goals": ["E5eW2VbR8L9b2uOarq3s", "PWsaOGHIsSX1NoHkn8wD", "5Tl2hVrt2BetONhVHFGb", "vNXllTmPiPWLLl08xxri", "xf3NBY8jN5MC0Xhb7MQU", "Y6IbZzWjBuJav0LzVIt0", "DTdcjsr3oe55F89EannD", "evFSNTz74SLBeEqcHWeL"]
	}, {
		"goalGroup": "1BW6pUbFpF77bRpdpaul",
		"goals": ["k9vRZB8uEeo47aJixv2b", "WnvVRiEIETS0b9JAK6fh", "s6Zi3dIj2FiMiX75FxeT", "4mulxh1oaS79Eh9ChFIP"]
	}, {
		"goalGroup": "skCEFaXepXHhbV5UEz8H",
		"goals": ["5xYvnV9U0OnVzmWhOGCt", "VouKOlCEWHpLPUweKiYQ", "nmz563qst2vYKAKuzEz1", "vhxICG24dM6i9iU1yrgG", "IWV2W14VaF0hf0oyLDOY", "YeFsrMTsTTUnEGvgFpTa", "u9sKA8vsyTaXSH8Rf7G6", "W5nqftweD6VJQBJtAJ3E", "yEDHfAiuZh9JN8jxHo0a", "KUc2b6iwzZuMY153a5FO", "Q9kvyv8TWhEN3UOCWA9r"]
	}, {
		"goalGroup": "sHai2tQJbjjXe6X8iY67",
		"goals": ["mHPVnA8DRwYd2JBE9rX2", "OMU93iIrAWq6IV48Ixdu", "hIfVGI8EBV7mySPITJHq"]
	}]
}, {
	"igid": "S14",
	"parts": [{
		"goalGroup": "oxm337xeBBjn5lK7QGEq",
		"goals": ["elmGrOybd4rSauQb8I9O", "6Cr9IbXn1EEgCoIDS2WG", "fZGBzy4qOfBtEwvJaxVh", "PRuAbuCoIRTMl5xNastc", "0j7aMOmQiZsOxsUTmsxP", "uQaSPT1w02Z6SUaLiNyJ", "AtA44UePNrB477WoevQ6", "f4zxiqoyC7ku9rZnhwd1", "h7dIMbTZ5l5V85HT2PTi", "Hm7CNPCTtLEIHsohGBlx", "9NYgZJ5D6Ro3vsb7TSZX", "BO3zzlhAf5UipPMl7d5x", "gHSy7XIWPH0hGo8psC5l", "y0yAj7fmiqddHgTcLEHf", "5nD0brWdQjzW2gOnsV2s", "izRq2vmlzqnYccJgeThG", "GTODvuG9EjtXBe9qJS65", "0Y4eKEy4rvyuwViuzmb2", "pe2agZdOycnyQSJytpfq", "3Jmx0IyArNP3UlyTV085"]
	}, {
		"goalGroup": "6HVUqhHse4RlZwyat0tU",
		"goals": ["EHNqY78g8EiDsFBQZsiM", "l5qQCdsktILMC86YMVMP", "BNiKcdVNFE8nCcNYDRk1", "vrb9vR1IBYEqk01clMoD", "718lmOXsSte3ar3I7MIW"]
	}, {
		"goalGroup": "3DBrDmy0hgn4HWliWpi3",
		"goals": ["n9D1n4DprY9rV5HvM94v", "CdnNWYWvXqVtIyovHjDC"]
	}, {
		"goalGroup": "zmGGmARgmLqtvXOF0nNa",
		"goals": ["65RZNwA94Fq9ohOrtEvS", "0SdTRGTVlVjQoTCsiKqC", "0YUBCQuxBOr8PROY9dIf", "Ibci9KWw8aWVxSN2RhEi", "Mk7hNPhWOF5EYb9gYi3i"]
	}, {
		"goalGroup": "TgWltqxxVBo73Av15ER6",
		"goals": ["oCSrzvT2HIpx6Hg6ppBn", "Vb1OdoFOTyi59kGpPUpq"]
	}, {
		"goalGroup": "O3Qw037SqCYhJs2NXmie",
		"goals": ["E5eW2VbR8L9b2uOarq3s", "PWsaOGHIsSX1NoHkn8wD", "5Tl2hVrt2BetONhVHFGb", "vNXllTmPiPWLLl08xxri", "xf3NBY8jN5MC0Xhb7MQU", "Y6IbZzWjBuJav0LzVIt0", "DTdcjsr3oe55F89EannD", "evFSNTz74SLBeEqcHWeL"]
	}, {
		"goalGroup": "1BW6pUbFpF77bRpdpaul",
		"goals": ["k9vRZB8uEeo47aJixv2b", "WnvVRiEIETS0b9JAK6fh", "s6Zi3dIj2FiMiX75FxeT", "4mulxh1oaS79Eh9ChFIP"]
	}, {
		"goalGroup": "skCEFaXepXHhbV5UEz8H",
		"goals": ["5xYvnV9U0OnVzmWhOGCt", "VouKOlCEWHpLPUweKiYQ", "nmz563qst2vYKAKuzEz1", "vhxICG24dM6i9iU1yrgG", "IWV2W14VaF0hf0oyLDOY", "YeFsrMTsTTUnEGvgFpTa", "u9sKA8vsyTaXSH8Rf7G6", "W5nqftweD6VJQBJtAJ3E", "yEDHfAiuZh9JN8jxHo0a", "KUc2b6iwzZuMY153a5FO", "Q9kvyv8TWhEN3UOCWA9r"]
	}, {
		"goalGroup": "sHai2tQJbjjXe6X8iY67",
		"goals": ["mHPVnA8DRwYd2JBE9rX2", "OMU93iIrAWq6IV48Ixdu", "hIfVGI8EBV7mySPITJHq"]
	}]
}, {
	"igid": "S15",
	"parts": [{
		"goalGroup": "oxm337xeBBjn5lK7QGEq",
		"goals": ["elmGrOybd4rSauQb8I9O", "6Cr9IbXn1EEgCoIDS2WG", "fZGBzy4qOfBtEwvJaxVh", "PRuAbuCoIRTMl5xNastc", "0j7aMOmQiZsOxsUTmsxP", "uQaSPT1w02Z6SUaLiNyJ", "AtA44UePNrB477WoevQ6", "f4zxiqoyC7ku9rZnhwd1", "h7dIMbTZ5l5V85HT2PTi", "Hm7CNPCTtLEIHsohGBlx", "9NYgZJ5D6Ro3vsb7TSZX", "BO3zzlhAf5UipPMl7d5x", "gHSy7XIWPH0hGo8psC5l", "y0yAj7fmiqddHgTcLEHf", "5nD0brWdQjzW2gOnsV2s", "izRq2vmlzqnYccJgeThG", "GTODvuG9EjtXBe9qJS65", "0Y4eKEy4rvyuwViuzmb2", "pe2agZdOycnyQSJytpfq", "3Jmx0IyArNP3UlyTV085", "dS5qGn8Yz3kRMWJrq3aA", "9fKjJI8Z1QgR3Lt2p4yq", "rRD0BD9D63PcBd5rLIIh", "q29Zm2qIUkfp20jDexDA", "BRUQOXVp5O3PbwELMWxx", "AzAVzSMyfoHhWHbGabGj", "REmqhUNleSKMDyIslu9S"]
	}, {
		"goalGroup": "6HVUqhHse4RlZwyat0tU",
		"goals": ["Ku8ZuzV9HtwoX0fMkeVJ", "pyddDTBIfNm7NEioX75R", "BNiKcdVNFE8nCcNYDRk1", "vrb9vR1IBYEqk01clMoD", "718lmOXsSte3ar3I7MIW"]
	}, {
		"goalGroup": "3DBrDmy0hgn4HWliWpi3",
		"goals": ["n9D1n4DprY9rV5HvM94v", "CdnNWYWvXqVtIyovHjDC"]
	}, {
		"goalGroup": "zmGGmARgmLqtvXOF0nNa",
		"goals": ["65RZNwA94Fq9ohOrtEvS", "0SdTRGTVlVjQoTCsiKqC", "0YUBCQuxBOr8PROY9dIf", "Ibci9KWw8aWVxSN2RhEi", "Mk7hNPhWOF5EYb9gYi3i"]
	}, {
		"goalGroup": "TgWltqxxVBo73Av15ER6",
		"goals": ["oCSrzvT2HIpx6Hg6ppBn", "Vb1OdoFOTyi59kGpPUpq"]
	}, {
		"goalGroup": "O3Qw037SqCYhJs2NXmie",
		"goals": ["E5eW2VbR8L9b2uOarq3s", "PWsaOGHIsSX1NoHkn8wD", "5Tl2hVrt2BetONhVHFGb", "vNXllTmPiPWLLl08xxri", "xf3NBY8jN5MC0Xhb7MQU", "Y6IbZzWjBuJav0LzVIt0", "DTdcjsr3oe55F89EannD", "evFSNTz74SLBeEqcHWeL"]
	}, {
		"goalGroup": "1BW6pUbFpF77bRpdpaul",
		"goals": ["k9vRZB8uEeo47aJixv2b", "WnvVRiEIETS0b9JAK6fh", "s6Zi3dIj2FiMiX75FxeT", "4mulxh1oaS79Eh9ChFIP"]
	}, {
		"goalGroup": "skCEFaXepXHhbV5UEz8H",
		"goals": ["5xYvnV9U0OnVzmWhOGCt", "VouKOlCEWHpLPUweKiYQ", "nmz563qst2vYKAKuzEz1", "vhxICG24dM6i9iU1yrgG", "IWV2W14VaF0hf0oyLDOY", "YeFsrMTsTTUnEGvgFpTa", "u9sKA8vsyTaXSH8Rf7G6", "W5nqftweD6VJQBJtAJ3E", "yEDHfAiuZh9JN8jxHo0a", "KUc2b6iwzZuMY153a5FO", "Q9kvyv8TWhEN3UOCWA9r"]
	}, {
		"goalGroup": "sHai2tQJbjjXe6X8iY67",
		"goals": ["mHPVnA8DRwYd2JBE9rX2", "OMU93iIrAWq6IV48Ixdu", "hIfVGI8EBV7mySPITJHq"]
	}]
}, {
	"igid": "S16",
	"parts": [{
		"goalGroup": "oxm337xeBBjn5lK7QGEq",
		"goals": ["elmGrOybd4rSauQb8I9O", "6Cr9IbXn1EEgCoIDS2WG", "fZGBzy4qOfBtEwvJaxVh", "PRuAbuCoIRTMl5xNastc", "0j7aMOmQiZsOxsUTmsxP", "uQaSPT1w02Z6SUaLiNyJ", "AtA44UePNrB477WoevQ6", "f4zxiqoyC7ku9rZnhwd1", "h7dIMbTZ5l5V85HT2PTi", "Hm7CNPCTtLEIHsohGBlx", "9NYgZJ5D6Ro3vsb7TSZX", "BO3zzlhAf5UipPMl7d5x", "gHSy7XIWPH0hGo8psC5l", "y0yAj7fmiqddHgTcLEHf", "5nD0brWdQjzW2gOnsV2s", "izRq2vmlzqnYccJgeThG", "GTODvuG9EjtXBe9qJS65", "0Y4eKEy4rvyuwViuzmb2", "pe2agZdOycnyQSJytpfq", "3Jmx0IyArNP3UlyTV085", "BmIaVHTCVq3QTyU4QIT5"]
	}, {
		"goalGroup": "6HVUqhHse4RlZwyat0tU",
		"goals": ["EHNqY78g8EiDsFBQZsiM", "l5qQCdsktILMC86YMVMP", "BNiKcdVNFE8nCcNYDRk1", "vrb9vR1IBYEqk01clMoD", "718lmOXsSte3ar3I7MIW"]
	}, {
		"goalGroup": "3DBrDmy0hgn4HWliWpi3",
		"goals": ["n9D1n4DprY9rV5HvM94v", "CdnNWYWvXqVtIyovHjDC"]
	}, {
		"goalGroup": "zmGGmARgmLqtvXOF0nNa",
		"goals": ["65RZNwA94Fq9ohOrtEvS", "0SdTRGTVlVjQoTCsiKqC", "0YUBCQuxBOr8PROY9dIf", "Ibci9KWw8aWVxSN2RhEi", "Mk7hNPhWOF5EYb9gYi3i"]
	}, {
		"goalGroup": "TgWltqxxVBo73Av15ER6",
		"goals": ["oCSrzvT2HIpx6Hg6ppBn", "Vb1OdoFOTyi59kGpPUpq"]
	}, {
		"goalGroup": "O3Qw037SqCYhJs2NXmie",
		"goals": ["E5eW2VbR8L9b2uOarq3s", "PWsaOGHIsSX1NoHkn8wD", "5Tl2hVrt2BetONhVHFGb", "vNXllTmPiPWLLl08xxri", "xf3NBY8jN5MC0Xhb7MQU", "Y6IbZzWjBuJav0LzVIt0", "DTdcjsr3oe55F89EannD", "evFSNTz74SLBeEqcHWeL", "MfB1FhCpVsrs1MzKf2N8"]
	}, {
		"goalGroup": "1BW6pUbFpF77bRpdpaul",
		"goals": ["k9vRZB8uEeo47aJixv2b", "WnvVRiEIETS0b9JAK6fh", "s6Zi3dIj2FiMiX75FxeT", "4mulxh1oaS79Eh9ChFIP"]
	}, {
		"goalGroup": "skCEFaXepXHhbV5UEz8H",
		"goals": ["5xYvnV9U0OnVzmWhOGCt", "VouKOlCEWHpLPUweKiYQ", "nmz563qst2vYKAKuzEz1", "vhxICG24dM6i9iU1yrgG", "IWV2W14VaF0hf0oyLDOY", "YeFsrMTsTTUnEGvgFpTa", "u9sKA8vsyTaXSH8Rf7G6", "W5nqftweD6VJQBJtAJ3E", "yEDHfAiuZh9JN8jxHo0a", "KUc2b6iwzZuMY153a5FO", "Q9kvyv8TWhEN3UOCWA9r"]
	}, {
		"goalGroup": "sHai2tQJbjjXe6X8iY67",
		"goals": ["mHPVnA8DRwYd2JBE9rX2", "OMU93iIrAWq6IV48Ixdu", "hIfVGI8EBV7mySPITJHq"]
	}]
}, {
	"igid": "S17",
	"parts": [{
		"goalGroup": "oxm337xeBBjn5lK7QGEq",
		"goals": ["elmGrOybd4rSauQb8I9O", "6Cr9IbXn1EEgCoIDS2WG", "fZGBzy4qOfBtEwvJaxVh", "PRuAbuCoIRTMl5xNastc", "0j7aMOmQiZsOxsUTmsxP", "uQaSPT1w02Z6SUaLiNyJ", "AtA44UePNrB477WoevQ6", "f4zxiqoyC7ku9rZnhwd1", "h7dIMbTZ5l5V85HT2PTi", "Hm7CNPCTtLEIHsohGBlx", "9NYgZJ5D6Ro3vsb7TSZX", "BO3zzlhAf5UipPMl7d5x", "gHSy7XIWPH0hGo8psC5l", "y0yAj7fmiqddHgTcLEHf", "5nD0brWdQjzW2gOnsV2s", "izRq2vmlzqnYccJgeThG", "GTODvuG9EjtXBe9qJS65", "0Y4eKEy4rvyuwViuzmb2", "pe2agZdOycnyQSJytpfq", "3Jmx0IyArNP3UlyTV085", "FiGcBByGR1zfCOuRpTDw", "6cMtCllRgGxO3sTu7Aj9", "aZcoqbts528eu7tJaT9v", "PpCiN4QzkauAE8V3IlTs", "GOtELHesBLAsvK5DJsEU"]
	}, {
		"goalGroup": "6HVUqhHse4RlZwyat0tU",
		"goals": ["EHNqY78g8EiDsFBQZsiM", "l5qQCdsktILMC86YMVMP", "BNiKcdVNFE8nCcNYDRk1", "vrb9vR1IBYEqk01clMoD", "718lmOXsSte3ar3I7MIW"]
	}, {
		"goalGroup": "3DBrDmy0hgn4HWliWpi3",
		"goals": ["n9D1n4DprY9rV5HvM94v", "CdnNWYWvXqVtIyovHjDC"]
	}, {
		"goalGroup": "zmGGmARgmLqtvXOF0nNa",
		"goals": ["65RZNwA94Fq9ohOrtEvS", "0SdTRGTVlVjQoTCsiKqC", "0YUBCQuxBOr8PROY9dIf", "Ibci9KWw8aWVxSN2RhEi", "Mk7hNPhWOF5EYb9gYi3i"]
	}, {
		"goalGroup": "TgWltqxxVBo73Av15ER6",
		"goals": ["oCSrzvT2HIpx6Hg6ppBn", "Vb1OdoFOTyi59kGpPUpq"]
	}, {
		"goalGroup": "O3Qw037SqCYhJs2NXmie",
		"goals": ["E5eW2VbR8L9b2uOarq3s", "PWsaOGHIsSX1NoHkn8wD", "5Tl2hVrt2BetONhVHFGb", "vNXllTmPiPWLLl08xxri", "xf3NBY8jN5MC0Xhb7MQU", "Y6IbZzWjBuJav0LzVIt0", "DTdcjsr3oe55F89EannD", "evFSNTz74SLBeEqcHWeL"]
	}, {
		"goalGroup": "1BW6pUbFpF77bRpdpaul",
		"goals": ["k9vRZB8uEeo47aJixv2b", "WnvVRiEIETS0b9JAK6fh", "s6Zi3dIj2FiMiX75FxeT", "4mulxh1oaS79Eh9ChFIP"]
	}, {
		"goalGroup": "skCEFaXepXHhbV5UEz8H",
		"goals": ["5xYvnV9U0OnVzmWhOGCt", "VouKOlCEWHpLPUweKiYQ", "nmz563qst2vYKAKuzEz1", "vhxICG24dM6i9iU1yrgG", "IWV2W14VaF0hf0oyLDOY", "YeFsrMTsTTUnEGvgFpTa", "u9sKA8vsyTaXSH8Rf7G6", "W5nqftweD6VJQBJtAJ3E", "yEDHfAiuZh9JN8jxHo0a", "KUc2b6iwzZuMY153a5FO", "Q9kvyv8TWhEN3UOCWA9r", "m7yw8c6uCmqHKyxbhvas", "ROmLaobMS39PJRUQER7G"]
	}, {
		"goalGroup": "sHai2tQJbjjXe6X8iY67",
		"goals": ["mHPVnA8DRwYd2JBE9rX2", "OMU93iIrAWq6IV48Ixdu", "hIfVGI8EBV7mySPITJHq"]
	}]
}, {
	"igid": "S18",
	"parts": [{
		"goalGroup": "oxm337xeBBjn5lK7QGEq",
		"goals": ["elmGrOybd4rSauQb8I9O", "6Cr9IbXn1EEgCoIDS2WG", "fZGBzy4qOfBtEwvJaxVh", "PRuAbuCoIRTMl5xNastc", "0j7aMOmQiZsOxsUTmsxP", "uQaSPT1w02Z6SUaLiNyJ", "AtA44UePNrB477WoevQ6", "f4zxiqoyC7ku9rZnhwd1", "h7dIMbTZ5l5V85HT2PTi", "Hm7CNPCTtLEIHsohGBlx", "9NYgZJ5D6Ro3vsb7TSZX", "BO3zzlhAf5UipPMl7d5x", "gHSy7XIWPH0hGo8psC5l", "y0yAj7fmiqddHgTcLEHf", "5nD0brWdQjzW2gOnsV2s", "izRq2vmlzqnYccJgeThG", "GTODvuG9EjtXBe9qJS65", "0Y4eKEy4rvyuwViuzmb2", "pe2agZdOycnyQSJytpfq", "3Jmx0IyArNP3UlyTV085", "rQ41V80Pgkn3PX1Wwu3k"]
	}, {
		"goalGroup": "6HVUqhHse4RlZwyat0tU",
		"goals": ["EHNqY78g8EiDsFBQZsiM", "l5qQCdsktILMC86YMVMP", "BNiKcdVNFE8nCcNYDRk1", "vrb9vR1IBYEqk01clMoD", "718lmOXsSte3ar3I7MIW"]
	}, {
		"goalGroup": "3DBrDmy0hgn4HWliWpi3",
		"goals": ["n9D1n4DprY9rV5HvM94v", "CdnNWYWvXqVtIyovHjDC"]
	}, {
		"goalGroup": "zmGGmARgmLqtvXOF0nNa",
		"goals": ["65RZNwA94Fq9ohOrtEvS", "0SdTRGTVlVjQoTCsiKqC", "0YUBCQuxBOr8PROY9dIf", "Ibci9KWw8aWVxSN2RhEi", "Mk7hNPhWOF5EYb9gYi3i", "74cNhNcxVA7aS75v5nHu"]
	}, {
		"goalGroup": "TgWltqxxVBo73Av15ER6",
		"goals": ["oCSrzvT2HIpx6Hg6ppBn", "Vb1OdoFOTyi59kGpPUpq"]
	}, {
		"goalGroup": "O3Qw037SqCYhJs2NXmie",
		"goals": ["E5eW2VbR8L9b2uOarq3s", "PWsaOGHIsSX1NoHkn8wD", "5Tl2hVrt2BetONhVHFGb", "vNXllTmPiPWLLl08xxri", "xf3NBY8jN5MC0Xhb7MQU", "Y6IbZzWjBuJav0LzVIt0", "DTdcjsr3oe55F89EannD", "evFSNTz74SLBeEqcHWeL"]
	}, {
		"goalGroup": "1BW6pUbFpF77bRpdpaul",
		"goals": ["k9vRZB8uEeo47aJixv2b", "WnvVRiEIETS0b9JAK6fh", "s6Zi3dIj2FiMiX75FxeT", "4mulxh1oaS79Eh9ChFIP"]
	}, {
		"goalGroup": "skCEFaXepXHhbV5UEz8H",
		"goals": ["5xYvnV9U0OnVzmWhOGCt", "VouKOlCEWHpLPUweKiYQ", "nmz563qst2vYKAKuzEz1", "vhxICG24dM6i9iU1yrgG", "IWV2W14VaF0hf0oyLDOY", "YeFsrMTsTTUnEGvgFpTa", "u9sKA8vsyTaXSH8Rf7G6", "W5nqftweD6VJQBJtAJ3E", "yEDHfAiuZh9JN8jxHo0a", "KUc2b6iwzZuMY153a5FO", "Q9kvyv8TWhEN3UOCWA9r", "QZRRz6NDKLDkxPzxl9yt", "WUVpfVc2vk7rkX9IWEZu", "QT1B5w6jlGMKGA3F6crh"]
	}, {
		"goalGroup": "sHai2tQJbjjXe6X8iY67",
		"goals": ["mHPVnA8DRwYd2JBE9rX2", "OMU93iIrAWq6IV48Ixdu", "hIfVGI8EBV7mySPITJHq"]
	}]
}, {
	"igid": "S19",
	"parts": [{
		"goalGroup": "oxm337xeBBjn5lK7QGEq",
		"goals": ["elmGrOybd4rSauQb8I9O", "6Cr9IbXn1EEgCoIDS2WG", "fZGBzy4qOfBtEwvJaxVh", "PRuAbuCoIRTMl5xNastc", "0j7aMOmQiZsOxsUTmsxP", "uQaSPT1w02Z6SUaLiNyJ", "AtA44UePNrB477WoevQ6", "f4zxiqoyC7ku9rZnhwd1", "h7dIMbTZ5l5V85HT2PTi", "Hm7CNPCTtLEIHsohGBlx", "9NYgZJ5D6Ro3vsb7TSZX", "BO3zzlhAf5UipPMl7d5x", "gHSy7XIWPH0hGo8psC5l", "y0yAj7fmiqddHgTcLEHf", "5nD0brWdQjzW2gOnsV2s", "izRq2vmlzqnYccJgeThG", "GTODvuG9EjtXBe9qJS65", "0Y4eKEy4rvyuwViuzmb2", "pe2agZdOycnyQSJytpfq", "3Jmx0IyArNP3UlyTV085"]
	}, {
		"goalGroup": "6HVUqhHse4RlZwyat0tU",
		"goals": ["EHNqY78g8EiDsFBQZsiM", "l5qQCdsktILMC86YMVMP", "BNiKcdVNFE8nCcNYDRk1", "vrb9vR1IBYEqk01clMoD", "718lmOXsSte3ar3I7MIW"]
	}, {
		"goalGroup": "3DBrDmy0hgn4HWliWpi3",
		"goals": ["n9D1n4DprY9rV5HvM94v", "CdnNWYWvXqVtIyovHjDC"]
	}, {
		"goalGroup": "zmGGmARgmLqtvXOF0nNa",
		"goals": ["65RZNwA94Fq9ohOrtEvS", "0SdTRGTVlVjQoTCsiKqC", "0YUBCQuxBOr8PROY9dIf", "Ibci9KWw8aWVxSN2RhEi", "Mk7hNPhWOF5EYb9gYi3i"]
	}, {
		"goalGroup": "TgWltqxxVBo73Av15ER6",
		"goals": ["oCSrzvT2HIpx6Hg6ppBn", "Vb1OdoFOTyi59kGpPUpq"]
	}, {
		"goalGroup": "O3Qw037SqCYhJs2NXmie",
		"goals": ["E5eW2VbR8L9b2uOarq3s", "PWsaOGHIsSX1NoHkn8wD", "5Tl2hVrt2BetONhVHFGb", "vNXllTmPiPWLLl08xxri", "xf3NBY8jN5MC0Xhb7MQU", "Y6IbZzWjBuJav0LzVIt0", "DTdcjsr3oe55F89EannD", "evFSNTz74SLBeEqcHWeL"]
	}, {
		"goalGroup": "1BW6pUbFpF77bRpdpaul",
		"goals": ["k9vRZB8uEeo47aJixv2b", "WnvVRiEIETS0b9JAK6fh", "s6Zi3dIj2FiMiX75FxeT", "4mulxh1oaS79Eh9ChFIP"]
	}, {
		"goalGroup": "skCEFaXepXHhbV5UEz8H",
		"goals": ["5xYvnV9U0OnVzmWhOGCt", "VouKOlCEWHpLPUweKiYQ", "nmz563qst2vYKAKuzEz1", "vhxICG24dM6i9iU1yrgG", "IWV2W14VaF0hf0oyLDOY", "YeFsrMTsTTUnEGvgFpTa", "u9sKA8vsyTaXSH8Rf7G6", "W5nqftweD6VJQBJtAJ3E", "yEDHfAiuZh9JN8jxHo0a", "KUc2b6iwzZuMY153a5FO", "Q9kvyv8TWhEN3UOCWA9r"]
	}, {
		"goalGroup": "sHai2tQJbjjXe6X8iY67",
		"goals": ["mHPVnA8DRwYd2JBE9rX2", "OMU93iIrAWq6IV48Ixdu", "hIfVGI8EBV7mySPITJHq"]
	}]
}, {
	"igid": "S20",
	"parts": [{
		"goalGroup": "oxm337xeBBjn5lK7QGEq",
		"goals": ["elmGrOybd4rSauQb8I9O", "6Cr9IbXn1EEgCoIDS2WG", "fZGBzy4qOfBtEwvJaxVh", "PRuAbuCoIRTMl5xNastc", "0j7aMOmQiZsOxsUTmsxP", "uQaSPT1w02Z6SUaLiNyJ", "AtA44UePNrB477WoevQ6", "f4zxiqoyC7ku9rZnhwd1", "h7dIMbTZ5l5V85HT2PTi", "Hm7CNPCTtLEIHsohGBlx", "9NYgZJ5D6Ro3vsb7TSZX", "BO3zzlhAf5UipPMl7d5x", "gHSy7XIWPH0hGo8psC5l", "y0yAj7fmiqddHgTcLEHf", "5nD0brWdQjzW2gOnsV2s", "izRq2vmlzqnYccJgeThG", "GTODvuG9EjtXBe9qJS65", "0Y4eKEy4rvyuwViuzmb2", "pe2agZdOycnyQSJytpfq", "3Jmx0IyArNP3UlyTV085"]
	}, {
		"goalGroup": "6HVUqhHse4RlZwyat0tU",
		"goals": ["EHNqY78g8EiDsFBQZsiM", "l5qQCdsktILMC86YMVMP", "BNiKcdVNFE8nCcNYDRk1", "vrb9vR1IBYEqk01clMoD", "718lmOXsSte3ar3I7MIW"]
	}, {
		"goalGroup": "3DBrDmy0hgn4HWliWpi3",
		"goals": ["n9D1n4DprY9rV5HvM94v", "CdnNWYWvXqVtIyovHjDC"]
	}, {
		"goalGroup": "zmGGmARgmLqtvXOF0nNa",
		"goals": ["65RZNwA94Fq9ohOrtEvS", "0SdTRGTVlVjQoTCsiKqC", "0YUBCQuxBOr8PROY9dIf", "Ibci9KWw8aWVxSN2RhEi", "Mk7hNPhWOF5EYb9gYi3i"]
	}, {
		"goalGroup": "TgWltqxxVBo73Av15ER6",
		"goals": ["oCSrzvT2HIpx6Hg6ppBn", "Vb1OdoFOTyi59kGpPUpq"]
	}, {
		"goalGroup": "O3Qw037SqCYhJs2NXmie",
		"goals": ["E5eW2VbR8L9b2uOarq3s", "PWsaOGHIsSX1NoHkn8wD", "5Tl2hVrt2BetONhVHFGb", "vNXllTmPiPWLLl08xxri", "xf3NBY8jN5MC0Xhb7MQU", "Y6IbZzWjBuJav0LzVIt0", "DTdcjsr3oe55F89EannD", "evFSNTz74SLBeEqcHWeL"]
	}, {
		"goalGroup": "1BW6pUbFpF77bRpdpaul",
		"goals": ["k9vRZB8uEeo47aJixv2b", "WnvVRiEIETS0b9JAK6fh", "s6Zi3dIj2FiMiX75FxeT", "4mulxh1oaS79Eh9ChFIP"]
	}, {
		"goalGroup": "skCEFaXepXHhbV5UEz8H",
		"goals": ["5xYvnV9U0OnVzmWhOGCt", "VouKOlCEWHpLPUweKiYQ", "nmz563qst2vYKAKuzEz1", "vhxICG24dM6i9iU1yrgG", "IWV2W14VaF0hf0oyLDOY", "YeFsrMTsTTUnEGvgFpTa", "u9sKA8vsyTaXSH8Rf7G6", "W5nqftweD6VJQBJtAJ3E", "yEDHfAiuZh9JN8jxHo0a", "KUc2b6iwzZuMY153a5FO", "Q9kvyv8TWhEN3UOCWA9r", "DEew7Qtc8DEggZHsoi8v", "rMbcDcPpjSnicVLISbAI", "dwm7Tnn4gyhPnKbd3hTf"]
	}, {
		"goalGroup": "sHai2tQJbjjXe6X8iY67",
		"goals": ["mHPVnA8DRwYd2JBE9rX2", "OMU93iIrAWq6IV48Ixdu", "hIfVGI8EBV7mySPITJHq"]
	}]
}, {
	"igid": "S21",
	"parts": [{
		"goalGroup": "oxm337xeBBjn5lK7QGEq",
		"goals": ["elmGrOybd4rSauQb8I9O", "6Cr9IbXn1EEgCoIDS2WG", "fZGBzy4qOfBtEwvJaxVh", "PRuAbuCoIRTMl5xNastc", "0j7aMOmQiZsOxsUTmsxP", "uQaSPT1w02Z6SUaLiNyJ", "AtA44UePNrB477WoevQ6", "f4zxiqoyC7ku9rZnhwd1", "h7dIMbTZ5l5V85HT2PTi", "Hm7CNPCTtLEIHsohGBlx", "9NYgZJ5D6Ro3vsb7TSZX", "BO3zzlhAf5UipPMl7d5x", "gHSy7XIWPH0hGo8psC5l", "y0yAj7fmiqddHgTcLEHf", "5nD0brWdQjzW2gOnsV2s", "izRq2vmlzqnYccJgeThG", "GTODvuG9EjtXBe9qJS65", "0Y4eKEy4rvyuwViuzmb2", "pe2agZdOycnyQSJytpfq", "3Jmx0IyArNP3UlyTV085"]
	}, {
		"goalGroup": "6HVUqhHse4RlZwyat0tU",
		"goals": ["EHNqY78g8EiDsFBQZsiM", "l5qQCdsktILMC86YMVMP", "BNiKcdVNFE8nCcNYDRk1", "vrb9vR1IBYEqk01clMoD", "718lmOXsSte3ar3I7MIW"]
	}, {
		"goalGroup": "3DBrDmy0hgn4HWliWpi3",
		"goals": ["n9D1n4DprY9rV5HvM94v", "CdnNWYWvXqVtIyovHjDC"]
	}, {
		"goalGroup": "zmGGmARgmLqtvXOF0nNa",
		"goals": ["65RZNwA94Fq9ohOrtEvS", "0SdTRGTVlVjQoTCsiKqC", "0YUBCQuxBOr8PROY9dIf", "Ibci9KWw8aWVxSN2RhEi", "Mk7hNPhWOF5EYb9gYi3i"]
	}, {
		"goalGroup": "TgWltqxxVBo73Av15ER6",
		"goals": ["oCSrzvT2HIpx6Hg6ppBn", "Vb1OdoFOTyi59kGpPUpq"]
	}, {
		"goalGroup": "O3Qw037SqCYhJs2NXmie",
		"goals": ["E5eW2VbR8L9b2uOarq3s", "PWsaOGHIsSX1NoHkn8wD", "5Tl2hVrt2BetONhVHFGb", "vNXllTmPiPWLLl08xxri", "xf3NBY8jN5MC0Xhb7MQU", "Y6IbZzWjBuJav0LzVIt0", "DTdcjsr3oe55F89EannD", "evFSNTz74SLBeEqcHWeL"]
	}, {
		"goalGroup": "1BW6pUbFpF77bRpdpaul",
		"goals": ["k9vRZB8uEeo47aJixv2b", "WnvVRiEIETS0b9JAK6fh", "s6Zi3dIj2FiMiX75FxeT", "4mulxh1oaS79Eh9ChFIP"]
	}, {
		"goalGroup": "skCEFaXepXHhbV5UEz8H",
		"goals": ["5xYvnV9U0OnVzmWhOGCt", "VouKOlCEWHpLPUweKiYQ", "nmz563qst2vYKAKuzEz1", "vhxICG24dM6i9iU1yrgG", "IWV2W14VaF0hf0oyLDOY", "YeFsrMTsTTUnEGvgFpTa", "u9sKA8vsyTaXSH8Rf7G6", "W5nqftweD6VJQBJtAJ3E", "yEDHfAiuZh9JN8jxHo0a", "KUc2b6iwzZuMY153a5FO", "Q9kvyv8TWhEN3UOCWA9r", "M2yUBm9WEy0I3wxjV0cx"]
	}, {
		"goalGroup": "sHai2tQJbjjXe6X8iY67",
		"goals": ["mHPVnA8DRwYd2JBE9rX2", "OMU93iIrAWq6IV48Ixdu", "hIfVGI8EBV7mySPITJHq", "8aLUXqW8nrXtF7NbKxJ3", "4gZVuTWuJ3xytlEzTcDV"]
	}]
}, {
	"igid": "S2",
	"parts": [{
		"goalGroup": "oxm337xeBBjn5lK7QGEq",
		"goals": ["elmGrOybd4rSauQb8I9O", "6Cr9IbXn1EEgCoIDS2WG", "fZGBzy4qOfBtEwvJaxVh", "PRuAbuCoIRTMl5xNastc", "0j7aMOmQiZsOxsUTmsxP", "uQaSPT1w02Z6SUaLiNyJ", "AtA44UePNrB477WoevQ6", "f4zxiqoyC7ku9rZnhwd1", "h7dIMbTZ5l5V85HT2PTi", "Hm7CNPCTtLEIHsohGBlx", "9NYgZJ5D6Ro3vsb7TSZX", "BO3zzlhAf5UipPMl7d5x", "gHSy7XIWPH0hGo8psC5l", "y0yAj7fmiqddHgTcLEHf", "5nD0brWdQjzW2gOnsV2s", "izRq2vmlzqnYccJgeThG", "GTODvuG9EjtXBe9qJS65", "0Y4eKEy4rvyuwViuzmb2", "pe2agZdOycnyQSJytpfq", "3Jmx0IyArNP3UlyTV085", "QLRH13vislXJqMxS1CMl", "H2ZemTSHrq0sHR0elyJ8"]
	}, {
		"goalGroup": "6HVUqhHse4RlZwyat0tU",
		"goals": ["EHNqY78g8EiDsFBQZsiM", "l5qQCdsktILMC86YMVMP", "BNiKcdVNFE8nCcNYDRk1", "vrb9vR1IBYEqk01clMoD", "718lmOXsSte3ar3I7MIW"]
	}, {
		"goalGroup": "3DBrDmy0hgn4HWliWpi3",
		"goals": ["n9D1n4DprY9rV5HvM94v", "CdnNWYWvXqVtIyovHjDC"]
	}, {
		"goalGroup": "zmGGmARgmLqtvXOF0nNa",
		"goals": ["65RZNwA94Fq9ohOrtEvS", "0SdTRGTVlVjQoTCsiKqC", "0YUBCQuxBOr8PROY9dIf", "Ibci9KWw8aWVxSN2RhEi", "Mk7hNPhWOF5EYb9gYi3i"]
	}, {
		"goalGroup": "TgWltqxxVBo73Av15ER6",
		"goals": ["oCSrzvT2HIpx6Hg6ppBn", "Vb1OdoFOTyi59kGpPUpq", "b4DsjPkTJE3grtqP78j9", "OdPRlAZxp8q0efMgHGpB"]
	}, {
		"goalGroup": "O3Qw037SqCYhJs2NXmie",
		"goals": ["E5eW2VbR8L9b2uOarq3s", "PWsaOGHIsSX1NoHkn8wD", "5Tl2hVrt2BetONhVHFGb", "vNXllTmPiPWLLl08xxri", "xf3NBY8jN5MC0Xhb7MQU", "Y6IbZzWjBuJav0LzVIt0", "DTdcjsr3oe55F89EannD", "evFSNTz74SLBeEqcHWeL", "tRxno2o9xD7koS4DjhIp", "V0InBUyDRBsnOV5v1yCL"]
	}, {
		"goalGroup": "1BW6pUbFpF77bRpdpaul",
		"goals": ["k9vRZB8uEeo47aJixv2b", "WnvVRiEIETS0b9JAK6fh", "s6Zi3dIj2FiMiX75FxeT", "4mulxh1oaS79Eh9ChFIP"]
	}, {
		"goalGroup": "skCEFaXepXHhbV5UEz8H",
		"goals": ["5xYvnV9U0OnVzmWhOGCt", "VouKOlCEWHpLPUweKiYQ", "nmz563qst2vYKAKuzEz1", "vhxICG24dM6i9iU1yrgG", "IWV2W14VaF0hf0oyLDOY", "YeFsrMTsTTUnEGvgFpTa", "u9sKA8vsyTaXSH8Rf7G6", "W5nqftweD6VJQBJtAJ3E", "yEDHfAiuZh9JN8jxHo0a", "KUc2b6iwzZuMY153a5FO", "Q9kvyv8TWhEN3UOCWA9r"]
	}, {
		"goalGroup": "sHai2tQJbjjXe6X8iY67",
		"goals": ["mHPVnA8DRwYd2JBE9rX2", "OMU93iIrAWq6IV48Ixdu", "hIfVGI8EBV7mySPITJHq"]
	}]
}]
<template>
	<div>
		<v-textarea v-model="paste" label="Paste"></v-textarea>
		<v-select multiple chips small-chips v-model="selected" :items="items" label="Goals"></v-select>
	</div>
</template>

<style lang="less">
	
</style>

<script type="text/javascript">
	import Vuex from "vuex";
	// import - from "@/components/"
	export default {
		name: "SectionPartWizard.vue", 
		props: {
			goalGroup: {}
		},
		data: () => {
			return {
				paste: "", 
				selected: []
			}
		},
		// components: {

		// },
		computed: {
			...Vuex.mapState({
				goals: state => state.goals,
			}),
			items(){
				return this.mwutilities.itemsArray( this.goals )
			}
		},
		watch: {
			paste(){
				var self = this;
				var items = self.paste.split("\n");
				items = items.map( g => g.trim() );
				var match = items.map( i => self.match( i ) )
				match = match.filter( m => m );
				self.selected = match;
			},
			selected(){
				this.$emit("selected", this.selected )
			}
		},
		methods: {
			match(i){
				var self = this;
				if( !i ){
					return false;
				}
				if( self.goals[i] ){
					return i
				}
				var match = Object.keys(self.goals).find( goalId => {
					if ( self.goals[goalId].igid == i || self.goals[goalId].name == i ){
						return goalId
					}
				})
				return match || false 
			}
		},
		created(){
			this.$store.dispatch("goals/fetchAll")			
		}

	}
// </script>"
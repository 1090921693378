<template>

	<v-sheet>
		<!-- <v-item-group v-model="toggleSection">
			<v-item v-slot="{ active, toggle }" v-for="(item, key) in sections" :key="key" :value="key">
				<v-chip :color="active ? 'green' : 'grey'" @click="toggle" class="mr-2 mb-2" > {{key}}: {{item.name}} </v-chip>
			</v-item>
		</v-item-group> -->
		<v-stepper vertical v-model="step">
			<v-stepper-step step="1" complete>  
				Section Info
			</v-stepper-step>
			<v-stepper-content step="1">
				<v-text-field 
					label="Name"
					v-model="name"
				></v-text-field>
				<v-text-field 
					label="IG ID"
					v-model="igid"
				></v-text-field>
				<p class="text-right"><v-btn @click="step++" color="primary">Next</v-btn></p>
			</v-stepper-content>	
			<v-stepper-step step="2" complete>  
				Departments
			</v-stepper-step>
			<v-stepper-content step="2">
				<v-autocomplete
					label="Departments"
					v-model="selectedDepartments"
					:items="departmentItems"
					chips small-chips
					multiple
				></v-autocomplete>
				<p class="text-right"><v-btn @click="step++" color="primary">Next</v-btn></p>
			</v-stepper-content>	
			<v-stepper-step step="3" complete>  
				Goal Groups
			</v-stepper-step>
			<v-stepper-content step="3">
				<v-textarea label="paste" v-model="pasteGoalGroups">
				</v-textarea>
				<v-select
					label="GoalsGroups"
					v-model="selectedGoalGroups"
					:items="goalGroupItems"
					chips small-chips
					multiple
				></v-select>
				<p class="text-right"><v-btn @click="step++" color="primary">Next</v-btn></p>
			</v-stepper-content>
			<v-stepper-step step="4" complete>  
				Goals
			</v-stepper-step>
			<v-stepper-content :step="4">
				<v-expansion-panels>
					<v-expansion-panel v-for="(part, g) in parts" :key="g">
						<v-expansion-panel-header v-if="goalGroups[g]">
							<span>
								{{goalGroups[g].name}}
								<v-badge :content="count(g)" color="green" class="ml-5"></v-badge>
							</span>
						</v-expansion-panel-header>
						<v-expansion-panel-content>
							<SectionPartWizard  @selected="data => selectGoals(g, data)"></SectionPartWizard>
						</v-expansion-panel-content>
					</v-expansion-panel>
				</v-expansion-panels>
				<p class="text-right">
					<v-btn @click="finish" color="green" dark>Finish</v-btn>
				</p>
			</v-stepper-content>
			<Saved :trigger="saved" @saved="saved = false"></Saved>
					
		</v-stepper>
	</v-sheet>
</template>

<style lang="less">
	
</style>

<script type="text/javascript">
	import Vue from "vue";
	import Vuex from "vuex";
	import Saved from "@/components/notices/Saved"
	import SectionPartWizard from "@/components/admin/forms/SectionPartWizard";
	import data from "@/components/admin/forms/data.js";
	export default {
		name: "SectionWizard", 
		props: {
			
		},
		data: () => {
			return {
				saved: false,
				step: 1, 
				name: "", 
				igid: "", 
				selectedDepartments: [],
				selectedGoalGroups: [ "oxm337xeBBjn5lK7QGEq", "6HVUqhHse4RlZwyat0tU", "3DBrDmy0hgn4HWliWpi3", "zmGGmARgmLqtvXOF0nNa", "TgWltqxxVBo73Av15ER6", "O3Qw037SqCYhJs2NXmie", "1BW6pUbFpF77bRpdpaul", "skCEFaXepXHhbV5UEz8H", "sHai2tQJbjjXe6X8iY67"], 
				pasteGoalGroups: "", 
				parts: {},
				sections: {
					S1: { name: "Geschäftsbereich Finanzen", department: "jOlF4Kr9RLgjlsiEw6c4"},
					S2: { name: "Geschäftsbereich Personal", department: "zcxX67kvz3mUZEoGcOfS"},
					S3: { name: "Geschäftsbereich Ambulanz- und Patientenmanagement", department: "n83h7MOxRn3mJuuR3uI8"},
					S4: { name: "Klinik für Radiologie", department: "NNWtsLhBHDCqMj3xEeyQ"},
					S5: { name: "Klinik für Anästhesiologie, operative Intensivmedizin und Schmerztherapie", department: "XrmiBxKa0SUTfUPSh5e8"},
					S6: { name: "Klinik für Hautkrankheiten - Allgemeine Dermatologie und Venerologie", department: "Y9elO7sG6yU4QNGUzAyF"},
					S7: { name: "Klinik für Herz- und Thoraxchirurgie", department: "GnQywjL6yLWy8DtuXif9"},
					S8: { name: "Klinik für Kardiologie I", department: "FBg0hYx3vOnzbRGBMGkv"},
					S9: { name: "Klinik für Kinder- und Jugendmedizin - Allgemeine Pädiatrie", department: "7pI2tFMCniY9fuqsPSXf"},
					S10: { name: "Klinik für Neurochirurgie", department: "0WW3xlEaHyHyejWkgw38"},
					S11: { name: "Klinik für Neurologie mit Institut für Translationale Neurologie", department: "rMITZMRVKCbPIrrHfURD"},
					S12: { name: "Klinik für Allgemein-, Viszeral- und Transplantationschirurgie", department: "THCDoV7LkNqpQSKynF9O"},
					S13: { name: "Klinik für Urologie und Kinderurologie", department: "p3qJdRS1iWvjv4Egjv9Q"},
					S14: { name: "Allgemeine Innere Medizin - Medizinische Klinik A", department: "HGikkAAR57bvHiVfMIyY"},
					S15: { name: "UKM Infrastruktur Management GmbH - Medizintechnik", department: "KM8pD8M4QjVc31gJF9bO"},
					S16: { name: "Zentrum für Zahn-, Mund- und Kieferheilkunde", department: "HWs00HXBJOEv4M3yOZBp"},
					S17: { name: "UKM-Vorstand - Pflegedirektion", department: "uSAaFqwSm34ot4HNg6wC"},
					S18: { name: "Klinik für Augenheilkunde", department: "BtnzPk2AioFlZnWn1mgS"},
					S19: { name: "Klinik für Unfall-, Hand- und Wiederherstellungschirurgie", department: "ewORYkzXyO5Kvs7eZeBs"},
					S20: { name: "Gerhard-Domagk-Institut für Pathologie", department: "HK5yJwChfWypcdyGOWOE"},
					S21: { name: "Geschäftsbereich Materialwirtschaft", department: "EuatyKPz70CO1x4Mct67"},
					S22: { name: "Geschäftsbereich IT", department: "thPzqRMZWfqsx5KjWVUp"}
				},
				rawData: data, 
				toggleSection: ""

			}
		},
		components: {
			SectionPartWizard,
			Saved
		},
		computed: {
			...Vuex.mapState({
				goalGroups:  state => state.goalGroups,
				departments: state => state.departments
			}),
			departmentItems(){
				return this.mwutilities.itemsArray(this.departments)
			},
			goalGroupItems(){
				return this.mwutilities.itemsArray(this.goalGroups)
			}
		},
		watch: {
			toggleSection(id){
				if( id ){
					const self = this;
					var section = self.sections[id];
					self.selectedDepartments = [section.department]
					self.name = section.name;
					self.igid = id;
					var data = self.rawData.find( r => r.igid == id );
					data.parts.forEach( part => {
						self.parts[part.goalGroup] = part.goals;
					})
				}
			},
			selectedGoalGroups: {
				immediate: true, 
				handler(){
					this.selectedGoalGroups.forEach( id => {
						if( !this.parts[id]  ){
							this.parts[id] = []
						}
					})
				}
			},
			pasteGoalGroups(){
				var self = this;
				var goalGroups = this.pasteGoalGroups.split("\n");
				goalGroups = goalGroups.map( g => g.trim() ).filter( g => g.length );
				var matched = goalGroups.map( g => self.match(g) ).filter( g => g ); 
				this.selectedGoalGroups = matched;
			}
		},
		methods: {
			count(g){
				return this.parts[g].length;
			},
			match(i){
				var self = this;
				if( !i ){
					return false;
				}
				if( self.goalGroups[i] ){
					return i
				}
				var match = Object.keys(self.goalGroups).find( goalGroupId => {
					if ( self.goalGroups[goalGroupId].igid == i || self.goalGroups[goalGroupId].name == i ){
						return goalGroupId
					}
				})
				return match || false 
			},
			selectGoals(id, goals) {
				Vue.set(this.parts, id, goals )
				// this.parts[id] = goals;
			}, 
			finish(){
				var self = this;
				self.$store.dispatch( "sections/new", {
					name: self.name, 
					igid: self.igid
				} ).then( id => {
					self.selectedDepartments.forEach( department => {
						this.$store.dispatch("updateDoc", {
							doc: `departments/${department}`, 
							data: {section: id }
						}  )
					})
					Object.keys(self.parts).forEach( g => {
						self.$store.dispatch("sections/addPart", {
							section: id, 
							data: {							
								goalGroup: g, 
								goals: self.parts[g]
							}
						})
					})
					self.saved = true
					self.step = 1;
				})
			}
		},
		created(){
			this.$store.dispatch("departments/fetchAll")			
			this.$store.dispatch("goalGroups/fetchAll")			
		}

	}
// </script>"